import React from "react";

import BookingEditView from "./BookingEditView";

const BookingEdit = ({
  total_amount,
  bookingId,
  fromDate,
  tillDate,
  phoneNo,
  sortField,
}) => {
  return (
    <BookingEditView
      total_amount={total_amount}
      bookingId={bookingId}
      fromDate={fromDate}
      tillDate={tillDate}
      phoneNo={phoneNo}
      sortField={sortField}
    />
  );
};

BookingEdit.propTypes = {};

export default BookingEdit;
