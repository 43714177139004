import moment from "moment";

export const onFilter = () => (dataIndex, value, record) =>
  record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());

const getDate = (dateString) => {
  var dateParts = dateString.split("/");
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject.getTime();
};

const parseSingleRecord = ({
  bookingId,
  transactionId,
  turfNumber,
  slotDate,
  slotTime,
  duration,
  amount,
  remainingAmount,
  sport,
  bookingStatus,
  bookingTimestamp,
}) => ({
  bookingId,
  transactionId,
  turf: turfNumber,
  bookingDate: moment(new Date(bookingTimestamp)).format("DD/MM/yyyy"),
  playingDate: slotDate,
  startTime: slotTime,
  duration,
  amount,
  remainingAmount,
  sport,
  bookingStatus,
});

const parseMyBookingsList = (list) => list.map((i) => parseSingleRecord(i));

export const parseMyBookings = (list) => {
  const result = parseMyBookingsList(list).reduce(
    (acc, item) => {
      const tday = moment(new Date()).format("DD/MM/yyyy");

      if (getDate(tday) > getDate(item.playingDate)) {
        return { ...acc, past: [...acc.past, item] };
      }
      if (getDate(tday) < getDate(item.playingDate)) {
        return { ...acc, upcoming: [...acc.upcoming, item] };
      }
      return { ...acc, today: [...acc.today, item] };
    },
    {
      past: [],
      today: [],
      upcoming: [],
    }
  );

  return result;
};

export const getBookingTimeFrame = (date) => {
  const tday = moment(new Date()).format("DD/MM/yyyy");

  if (getDate(tday) > getDate(date)) {
    return "past";
  }

  if (getDate(tday) < getDate(date)) {
    return "upcoming";
  }

  return "today";
};
