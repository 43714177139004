import React from "react";
import { useSelector } from "react-redux";
import { getPromptData } from "../../dux/prompt";
import { promptCategoryType } from "../../helpers/constants";
import Login from "../shared/Login/Login";
import GenericError from "./ErrorPrompt/GenericError";
import BookingEdit from "../shared/BookingEdit/BookingEdit";

const PromptHandler = () => {
  const {
    category,
    data: {
      message,
      status,
      total_amount,
      bookingId,
      fromDate,
      tillDate,
      phoneNo,
      sortField,
    },
  } = useSelector(getPromptData);
  const { LOGIN, GENERIC_ERROR, BOOKING_EDIT } = promptCategoryType;

  return (
    <>
      {category === BOOKING_EDIT && (
        <BookingEdit
          total_amount={total_amount}
          bookingId={bookingId}
          fromDate={fromDate}
          tillDate={tillDate}
          phoneNo={phoneNo}
          sortField={sortField}
        />
      )}
      {category === LOGIN && <Login />}
      {category === GENERIC_ERROR && (
        <GenericError message={message} status={status} />
      )}
    </>
  );
};

export default PromptHandler;
