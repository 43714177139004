import { resetBookingRecord } from "../dux/bookingRecords";
import { resetBookNowDetail } from "../dux/bookNow";
import { reset } from "../dux/login";
import { resetMyBookings } from "../dux/myBookings";
import { RESET_STORE } from "../dux/store";

const store = () => (next) => (action) => {
  next(action);

  if (action.type === RESET_STORE) {
    next(reset());
    next(resetBookNowDetail());
    next(resetBookingRecord());
    next(resetMyBookings());
  }
};

export default store;
