import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutUser } from "../../../dux/login";
import {
  destroySession,
  getName,
  isAuthorized,
} from "../../../helpers/sessionStorageHelper";
import styles from "./LoginPanel.module.css";

const LoginPanel = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const name = getName();

  const signout = () => {
    dispatch(logOutUser());
    destroySession();
    navigate("/");
  };

  const goToAllBookingPage = () => {
    navigate("booking-records");
  };

  const goToMyAccount = () => {
    navigate("my-account/profile");
  };

  const goToMarkDays = () => {
    navigate("/mark-date-off-or-holiday");
  };

  const goToBlockSlots = () => {
    navigate("/block-slots");
  };

  const gotToGeneralRatesSetting = () => {
    navigate("admin-portal/general-rates");
  };

  return (
    <div className={styles.container}>
      <div className={styles.row} onClick={goToMyAccount}>
        <span className={styles.nameInitial}>{name[0]}</span>
        <span className={styles.text}>My Account</span>
      </div>
      {isAuthorized("ADMIN") && (
        <>
          <div className={styles.row} onClick={goToAllBookingPage}>
            <span className={styles.pageIcon} />
            <span className={styles.text}>View All Bookings</span>
          </div>
          <div className={styles.row} onClick={goToBlockSlots}>
            <span className={styles.arrowIcon} />
            <span className={styles.text}>Block/Unblock Slots</span>
          </div>
          <div className={styles.row} onClick={goToMarkDays}>
            <span className={styles.arrowIcon} />
            <span className={styles.text}>Mark days Off/Holiday</span>
          </div>
        </>
      )}
      {isAuthorized("SUPER_ADMIN") && (
        <div className={styles.row} onClick={gotToGeneralRatesSetting}>
          <span className={styles.settingsIcon} />
          <span className={styles.text}>Rates Setting</span>
        </div>
      )}
      <div data-testid="signoutRow" className={styles.row} onClick={signout}>
        <span className={styles.signoutIcon} />
        <span className={styles.text}>Logout</span>
      </div>
    </div>
  );
};

export default LoginPanel;
