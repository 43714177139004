import { snackBarStatus } from '../helpers/constants';

const prefix = 'snackbar';
export const OPEN_SNACKBAR = `${prefix}/open`;
export const CLOSE_SNACKBAR = `${prefix}/close`;

export const openSnackbar = ({ message, status }) => ({
  type: OPEN_SNACKBAR,
  payload: { message, status }
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR
});

const initialState = {
  message: '',
  status: snackBarStatus.SUCCESS
};

const snackbarReducer = (state = initialState, action) => {
  if (action.type === OPEN_SNACKBAR) {
    return {
      showSnackbar: true,
      ...action.payload
    };
  }

  if (action.type === CLOSE_SNACKBAR) {
    return initialState;
  }

  return state;
};

export default snackbarReducer;

// selectors

export const getSnackbarData = ({ snackbar: { message, status } }) => ({
  message,
  status
});
