import { apiAction, API_SUCCESS, API_ERROR } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import { SAVE_PROFILE } from "../dux/profile";
import { openSnackbar } from "../dux/snackbar";
import { getBaseApiUrl } from "../helpers/generic";
import { updateUser } from "../helpers/sessionStorageHelper";

const profile = () => (next) => (action) => {
  next(action);

  if (action.type === SAVE_PROFILE) {
    const { firstName, lastName, emailAddress, mobileNumber } = action.payload;
    next(apiCallStart({ api: "saveProfile" }));
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/users/save-details`,
        feature: SAVE_PROFILE,
        method: "POST",
        data: { firstName, lastName, emailAddress },
        meta: { firstName, lastName, emailAddress, mobileNumber },
      })
    );
  }

  if (action.type === `${SAVE_PROFILE} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "saveProfile" }));
    updateUser(action.meta);
    next(
      openSnackbar({
        message: "User details are updated successfully.",
      })
    );
  }

  if (action.type === `${SAVE_PROFILE} ${API_ERROR}`) {
    next(apiCallComplete({ api: "saveProfile" }));
  }
};

export default profile;
