export const NEW_BOOKING_COMPLETE = "NEW_BOOKING_COMPLETE";
export const RESET_NEW_BOOKING_FLAG = "RESET_NEW_BOOKING_FLAG";

export const completeBooking = () => ({
  type: NEW_BOOKING_COMPLETE,
});

export const resetBookingFlag = () => ({
  type: RESET_NEW_BOOKING_FLAG,
});

const initialState = {
  bookingComplete: false,
};

const uiBookNow = (state = initialState, action) => {
  if (action.type === NEW_BOOKING_COMPLETE) {
    return { bookingComplete: true };
  }
  if (action.type === RESET_NEW_BOOKING_FLAG) {
    return initialState;
  }

  return state;
};

export default uiBookNow;

export const getNewBookingFlag = ({ uiBookNow: { bookingComplete } }) =>
  bookingComplete;
