import { Button } from "@mui/material";
import ImageGallery from "react-image-gallery";

import { themeStyles } from "../../common/styles";
import fb from "../../assets/social/facebook.svg";
import insta from "../../assets/social/instagram.svg";

// import facilitiesImage from "../../assets/facilities-1.jpg";
// import eventsImage from "../../assets/events.jpg";

import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.jpeg";
import image3 from "../../assets/image3.jpg";
import image4 from "../../assets/image4.jpg";
import image5 from "../../assets/image5.jpg";
import image6 from "../../assets/image6.jpg";
import image7 from "../../assets/image7.jpg";
import image8 from "../../assets/image8.jpg";
import image9 from "../../assets/image9.jpg";
import image10 from "../../assets/image10.jpg";
import image11 from "../../assets/image11.jpg";
import image12 from "../../assets/image12.jpg";
import image13 from "../../assets/image13.jpg";
import image14 from "../../assets/image14.jpeg";
import image15 from "../../assets/image15.jpeg";
import image16 from "../../assets/image16.jpg";
import image17 from "../../assets/image17.jpeg";
import image18 from "../../assets/image18.jpeg";
import image19 from "../../assets/image19.jpeg";
import image20 from "../../assets/image20.jpeg";
import image21 from "../../assets/image21.jpeg";

import "./main.scss";
import { useNavigate } from "react-router-dom";

const MainComponent = () => {
  const navigate = useNavigate();

  const images = [
    {
      original: image1,
    },
    {
      original: image2,
    },
    {
      original: image8,
    },
    {
      original: image3,
    },
    {
      original: image12,
    },
    {
      original: image4,
    },
    {
      original: image13,
    },
    {
      original: image5,
    },
    {
      original: image11,
    },
    {
      original: image6,
    },
    {
      original: image10,
    },
    {
      original: image7,
    },
    {
      original: image9,
    },
    {
      original: image14,
    },
    {
      original: image15,
    },
    {
      original: image16,
    },
    {
      original: image17,
    },
    {
      original: image18,
    },
    {
      original: image19,
    },
    {
      original: image20,
    },
    {
      original: image21,
    },
  ];

  function random_sort(a, b) {
    return Math.random() - 0.5;
  }

  images.sort(random_sort);
  return (
    <>
      <div className="main-background">
        <div className="gallery-wrapper">
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            lazyload={true}
            autoPlay={true}
            className="gallery-image"
          />
        </div>

        <div className="book-now-btn-wrapper">
          <Button
            className="book-now-btn"
            key="login-btn"
            sx={{
              backgroundColor: themeStyles.secondaryColor,
              color: themeStyles.secondaryTextColor,
              textTransform: "unset",
            }}
            style={{ width: "160px", height: "60px", fontSize: "20px" }}
            href="/book-now"
          >
            Book Now
          </Button>
        </div>
        <div className="social-stack">
          <div className="animated bounce">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/WHSSportsHub"
            >
              <img
                src={fb}
                alt="Facebook"
                loading="lazy"
                style={{ width: "44px", marginRight: "20px" }}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/whssportshub?igshid=YmMyMTA2M2Y="
            >
              <img
                src={insta}
                alt="Instagram"
                loading="lazy"
                style={{ width: "48px" }}
              />
            </a>
          </div>
        </div>
        <div className="links">
          <div onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
          <span>|</span>
          <div onClick={() => navigate("/terms-and-conditions")}>
            Terms & Conditions
          </div>
          <span>|</span>
          <div onClick={() => navigate("/refund-policy")}>
            Cancellation/Refund Policies
          </div>
        </div>
      </div>
    </>
  );
};

export default MainComponent;
