import { apiAction, API_SUCCESS } from "../dux/api";
import {
  FETCH_BOOKING_SETTING,
  UPDATE_BOOKING_SETTING,
  setBookingSetting,
} from "../dux/bookingSetting";

import { parseBookingRecords } from "../helpers/bookingRecordsHelper";
import { apiCallComplete } from "../dux/apiStatus";
import { openSnackbar } from "../dux/snackbar";
import { API_ERROR } from "../dux/api";
import {
  getFetchBookingSettingUrl,
  getUpdateBookingSettingUrl,
} from "../helpers/apiEndPoints";

const bookingSetting = () => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_BOOKING_SETTING) {
    next(
      apiAction({
        url: getUpdateBookingSettingUrl(action.payload.sport),
        method: "PUT",
        feature: UPDATE_BOOKING_SETTING,
        data: {
          json: action.payload.json ?? {},
        },
      })
    );
  }

  if (action.type === FETCH_BOOKING_SETTING) {
    next(
      apiAction({
        url: getFetchBookingSettingUrl(action.payload.sport),
        feature: FETCH_BOOKING_SETTING,
      })
    );
  }
  if (action.type === `${FETCH_BOOKING_SETTING} ${API_SUCCESS}`) {
    next(
      setBookingSetting({
        bookingSetting:
          action.payload ??
          action.payload.response ??
          action.payload.response.json,
      })
    );
  }
  if (action.type === `${UPDATE_BOOKING_SETTING} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "bookingSetting" }));
    next(
      openSnackbar({
        message: "Setting has been updated successfully.",
      })
    );
  }

  if (action.type === `${UPDATE_BOOKING_SETTING} ${API_ERROR}`) {
    next(apiCallComplete({ api: "bookingSetting" }));
  }
};

export default bookingSetting;
