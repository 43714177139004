const prefix = "myBookingTab";
export const SET_TAB = `${prefix}/set`;
export const RESET_TAB = `${prefix}/reset`;

export const setTab = ({ tab }) => ({
  type: SET_TAB,
  payload: { tab },
});

export const resetTab = () => ({
  type: RESET_TAB,
});

const initialState = {
  activeTab: "today",
};

const myBookingTabReducer = (state = initialState, action) => {
  if (action.type === SET_TAB) {
    return {
      activeTab: action.payload.tab,
    };
  }

  if (action.type === RESET_TAB) {
    return initialState;
  }

  return state;
};

export default myBookingTabReducer;

// selectors

export const getActiveTab = ({ myBookingTab: { activeTab } }) => activeTab;
