import { apiAction, API_ERROR, API_SUCCESS } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import {
  MARK_DAYS_OFF_HOLIDAY,
  FETCH_MARK_DAYS_OFF_HOLIDAY,
  setMarkDaysOffHoliday,
  fetchMarkDaysOffHoliday,
} from "../dux/markDaysOffHoliday";
import { openSnackbar } from "../dux/snackbar";
import {
  getFetchMarkedDaysOffHolidayUrl,
  getMarkDaysOffHolidayUrl,
} from "../helpers/apiEndPoints";
import { getBaseApiUrl } from "../helpers/generic";

const markDaysOffHoliday =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === FETCH_MARK_DAYS_OFF_HOLIDAY) {
      next(apiCallStart({ api: "fetchMarkOffHoliday" }));
      next(
        apiAction({
          url: getFetchMarkedDaysOffHolidayUrl(action.payload.sport),
          feature: FETCH_MARK_DAYS_OFF_HOLIDAY,
        })
      );
    }

    if (action.type === `${FETCH_MARK_DAYS_OFF_HOLIDAY} ${API_SUCCESS}`) {
      next(apiCallComplete({ api: "fetchMarkOffHoliday" }));
      next(setMarkDaysOffHoliday(action.payload.response));
    }

    if (action.type === `${FETCH_MARK_DAYS_OFF_HOLIDAY} ${API_ERROR}`) {
      next(apiCallComplete({ api: "fetchMarkOffHoliday" }));
    }

    if (action.type === MARK_DAYS_OFF_HOLIDAY) {
      const { dateArr, type, sport } = action.payload;
      console.log(sport);
      next(apiCallStart({ api: "markOffHoliday" }));
      next(
        apiAction({
          url: getMarkDaysOffHolidayUrl(sport),
          feature: MARK_DAYS_OFF_HOLIDAY,
          method: "POST",
          data: { dateArr, type },
          meta: { sport },
        })
      );
    }

    if (action.type === `${MARK_DAYS_OFF_HOLIDAY} ${API_SUCCESS}`) {
      next(apiCallComplete({ api: "markOffHoliday" }));
      next(
        openSnackbar({
          message: "Days have been marked holiday/off successfully.",
        })
      );
      dispatch(fetchMarkDaysOffHoliday({ sport: action.meta.sport }));
    }

    if (action.type === `${MARK_DAYS_OFF_HOLIDAY} ${API_ERROR}`) {
      next(apiCallComplete({ api: "markOffHoliday" }));
    }
  };

export default markDaysOffHoliday;
