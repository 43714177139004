import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styles from './Modal.module.css';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

const Modal = (props) => {
  const { isOpen = true } = props;

  return (
    <ReactModal
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={props.handleClose}
      isOpen={isOpen}
    >
      {props.children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

export default Modal;
