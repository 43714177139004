import { Paper, Typography } from "@mui/material";

import eventsImage from "../../assets/events.jpg";

const Events = () => {
  return (
    <div className="main-wrapper">
      <div className="background">
        <img
          src={eventsImage}
          alt="Footbal"
          loading="lazy"
          style={{ width: "100%" }}
        />
      </div>

      <div className="white-paper">
        <Paper
          style={{
            width: "88%",
            position: "relative",
            top: "-15px",
            height: "calc(100% + 15px)",
            padding: "20px 32px 20px 32px",
            boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.09)",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            style={{ color: "#01245a", marginBottom: "20px" }}
          >
            <strong>Events</strong>
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#797979", fontSize: "14px" }}
          >
            WHS Sports Hub is Srinagar’s premiere location for countless event
            opportunities. Our events are well organised, fully staffed, and
            held in the best sports facility in Srinagar. Have a look at our
            schedule to see some of the upcoming events being hosted at WHS
            Sports Hub.
          </Typography>
        </Paper>
      </div>
      <div className="footer"></div>
    </div>
  );
};

export default Events;
