export const promptCategoryType = {
  LOGIN: "LOGIN",
  GENERIC_ERROR: "GENERIC_ERROR",
  BOOKING_EDIT: "BOOKING_EDIT",
};

export const SNACKBAR_TIME = 5; // 5 seconds

export const snackBarStatus = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const mapTurfLabels = {
  "Box Cricket": {
    TURF_A: "5 a side Turf A",
    TURF_B: "5 a side Turf B",
    TURF_C: "7 a side Turf C",
  },
  Football: {
    TURF_A: "5 a side Turf A",
    TURF_B: "5 a side Turf B",
    TURF_C: "7 a side Turf C",
  },
  "Cricket Net": {
    TURF_A: "Net A (3 Players Max)",
    TURF_B: "Net B (3 Players Max)",
    TURF_D: "Net A (6 Players Max)",
    TURF_E: "Net B (6 Players Max)",
  },
  "Pickle Ball": {
    TURF_A: "Court A",
  },
};
