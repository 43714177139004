import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import cx from "classnames";
import { isEmpty } from "ramda";
import Calendar from "./Calendar/Calendar";
import { fetchBookNowDetail, getBookNowData } from "../../dux/bookNow";
import { mapStartTimeToIndex, mapTurf } from "../../helpers/bookNowHelper";
import styles from "./BlockSlot.module.css";

import {
  apiCallComplete,
  isBlockUnblockPending,
  isFetchBookNowPending,
} from "../../dux/apiStatus";
import Loader from "../shared/UI/Loader/Loader";
import LoaderForButton from "../shared/UI/LoaderForButton";
import { blockUnblockSlot } from "../../dux/blockSlot";

const BlockSlot = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(dayjs());
  const [allSlots, setAllSlots] = useState([]);
  const [toBeAvailableList, setToBeAvailableList] = useState([]);
  const [toBeUnavailableList, setToBeUnavailableList] = useState([]);
  const [selectedSport, setSelectedSport] = useState("Football");
  const [selectedTurf, setSelectedTurf] = useState("turfA");
  const fetchingBookNowDetail = useSelector(isFetchBookNowPending);
  const blockUnblockPending = useSelector(isBlockUnblockPending);

  const timeFormat = (timeString) => {
    const newTime = timeString.split(" - ");

    const timeString12hr = new Date(
      "1970-01-01T" + newTime[0] + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

    const timeString12hr_ = new Date(
      "1970-01-01T" + newTime[1] + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

    return `${timeString12hr} - ${timeString12hr_}`;
  };

  const { bookingAllowed, slotsAvailability, slots } =
    useSelector(getBookNowData);

  const dateChangeHandler = (value) => {
    dispatch(
      fetchBookNowDetail({
        date: value.format("DD/MM/YYYY"),
        sport: selectedSport,
      })
    );
    setDate(value);
    setSelectedTurf("turfA");
    setToBeAvailableList([]);
    setToBeUnavailableList([]);
  };

  const turfChangeHandler = (value) => {
    setSelectedTurf(value);
    setToBeAvailableList([]);
    setToBeUnavailableList([]);
  };

  useEffect(() => {
    setAllSlots(slots.turfA);
  }, [slots]);

  useEffect(() => {
    setSelectedTurf("turfA");
    setToBeAvailableList([]);
    setToBeUnavailableList([]);
    dispatch(
      fetchBookNowDetail({
        date: date.format("DD/MM/YYYY"),
        sport: selectedSport,
      })
    );
    return () => {
      dispatch(apiCallComplete({ api: "blockUnblock" }));
    };
  }, [selectedSport]);

  const getTimeSlot = (time) =>
    `${time} - ${moment(time, ["h:m a", "H:m"])
      .add(1, "hour")
      .format("HH:mm")}`;

  const isSlotUnavailable = (time) => {
    const index = mapStartTimeToIndex[time];
    return !slotsAvailability[selectedTurf][index];
  };

  const slotClickHandler = (time, isSlotNotAvailable) => {
    if (isSlotNotAvailable) {
      if (toBeAvailableList.includes(time)) {
        setToBeAvailableList((prev) => prev.filter((i) => i !== time));
      } else {
        setToBeAvailableList((prev) => [...prev, time]);
      }
    }

    if (!isSlotNotAvailable) {
      if (toBeUnavailableList.includes(time)) {
        setToBeUnavailableList((prev) => prev.filter((i) => i !== time));
      } else {
        setToBeUnavailableList((prev) => [...prev, time]);
      }
    }
  };

  const getSlotArr = () => {
    const toBeAvailableIndexList = toBeAvailableList.map(
      (time) => mapStartTimeToIndex[time]
    );
    const toBeUnavailableIndexList = toBeUnavailableList.map(
      (time) => mapStartTimeToIndex[time]
    );

    return slotsAvailability[selectedTurf].map((flag, index) => {
      if (
        toBeAvailableIndexList.includes(index) ||
        toBeAvailableIndexList.includes(index - 1) ||
        toBeUnavailableIndexList.includes(index) ||
        toBeUnavailableIndexList.includes(index - 1)
      ) {
        return !flag;
      }
      return flag;
    });
  };

  const submitHandler = () => {
    dispatch(
      blockUnblockSlot({
        slotDate: date.format("DD/MM/YYYY"),
        slotArr: getSlotArr(),
        turfNumber: mapTurf[selectedTurf],
        sport: selectedSport,
      })
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <div className={styles.sportContainer}>
            <h3 className={styles.heading3}>Select Sports:</h3>
            <div className={styles.sportsCards}>
              <button
                onClick={() => setSelectedSport("Football")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Football",
                })}
              >
                <div className={styles.footballSportCard} />
                <span>Football</span>
              </button>
              <button
                onClick={() => setSelectedSport("Box Cricket")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Box Cricket",
                })}
              >
                <div className={styles.cricketSportCard} />
                <span>Box Cricket</span>
              </button>

              <button
                onClick={() => setSelectedSport("Cricket Net")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Cricket Net",
                })}
              >
                <div className={styles.cricketSportCard} />
                <span>Cricket Nets</span>
              </button>

              <button
                onClick={() => setSelectedSport("Pickle Ball")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Pickle Ball",
                })}
              >
                <div className={styles.pickeBallSportCard} />
                <span>Pickleball</span>
              </button>
            </div>
          </div>
          <h3 className={styles.heading3}>Select Date:</h3>
          <Calendar value={date} setValue={dateChangeHandler} />
          <div className={styles.date}>{date.format("DD MMM, YYYY")}</div>
        </div>
        <div className={styles.turfContainer}>
          {fetchingBookNowDetail && <Loader size="large" />}

          {!fetchingBookNowDetail && bookingAllowed ? (
            <>
              <div>
                <div className={styles.slotContainer}>
                  <h3 className={styles.heading3}>
                    Select {selectedSport === "Cricket Net" ? "Net" : "Turf"}:
                  </h3>
                  <div className={styles.availableTurf}>
                    {selectedSport === "Pickle Ball" && (
                      // Code for Pickle Ball Net

                      <button
                        onClick={() => turfChangeHandler("turfA")}
                        className={cx(styles.turfCard, {
                          [styles.selectedTurf]: selectedTurf === "turfA",
                        })}
                      >
                        Court A
                      </button>
                    )}
                    {selectedSport === "Cricket Net" && (
                      // Code for Cricket Net
                      <>
                        <button
                          onClick={() => turfChangeHandler("turfA")}
                          className={cx(styles.turfCard, {
                            [styles.selectedTurf]: selectedTurf === "turfA",
                          })}
                        >
                          Net A
                        </button>

                        <button
                          onClick={() => turfChangeHandler("turfB")}
                          className={cx(styles.turfCard, {
                            [styles.selectedTurf]: selectedTurf === "turfB",
                          })}
                        >
                          Net B
                        </button>
                      </>
                    )}
                    {["Football", "Box Cricket"].includes(selectedSport) && (
                      <>
                        <button
                          onClick={() => turfChangeHandler("turfA")}
                          className={cx(styles.turfCard, {
                            [styles.selectedTurf]: selectedTurf === "turfA",
                          })}
                        >
                          5 a side Turf A
                        </button>

                        <button
                          onClick={() => turfChangeHandler("turfB")}
                          className={cx(styles.turfCard, {
                            [styles.selectedTurf]: selectedTurf === "turfB",
                          })}
                        >
                          5 a side Turf B
                        </button>
                        <button
                          onClick={() => turfChangeHandler("turfC")}
                          className={cx(styles.turfCard, {
                            [styles.selectedTurf]: selectedTurf === "turfC",
                          })}
                        >
                          7 a side Turf C
                        </button>
                      </>
                    )}
                  </div>
                  <h3 className={styles.heading3}>Select Slot:</h3>
                  <div className={styles.slots}>
                    {(allSlots || []).map(({ selected = false, time }) => (
                      <button
                        key={time}
                        onClick={() =>
                          slotClickHandler(time, isSlotUnavailable(time))
                        }
                        className={cx(styles.slot, styles.available, {
                          [styles.selectedTime]: selected,
                          [styles.unavailable]: isSlotUnavailable(time),
                          [styles.madeUnavailable]:
                            toBeUnavailableList.includes(time),
                          [styles.madeAvailable]:
                            toBeAvailableList.includes(time),
                        })}
                      >
                        {timeFormat(getTimeSlot(time))}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className={styles.msg}>
              Bookings are not allowed for the selected date.
            </span>
          )}
        </div>
      </div>
      <div className={styles.btnContainer}>
        <button
          className={styles.btn}
          onClick={submitHandler}
          disabled={isEmpty(toBeAvailableList) && isEmpty(toBeUnavailableList)}
        >
          {blockUnblockPending ? <LoaderForButton /> : "PROCEED"}
        </button>
      </div>
    </>
  );
};

export default BlockSlot;
