import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Avatar } from "antd";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../helpers/sessionStorageHelper";
import Feedback from "./Feedback";
import styles from "./MyAccount.module.css";
import MyBookings from "./MyBookings";
import MyProfile from "./MyProfile";

const Tabs = [
  { title: "My Profile", key: "profile" },
  { title: "My Bookings", key: "bookings" },
  { title: "Feedback", key: "feedback" },
];

const MyAccount = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { mobileNumber } = getUser();

  const changeHandler = (key) => {
    navigate(`/my-account/${key}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainWrapper}>
        <div className={styles.sidebar}>
          <div className={styles.profile}>
            <Avatar
              icon={<PersonRoundedIcon style={{ width: 200, height: 200 }} />}
              style={{ width: 200, height: 200 }}
            >
              J
            </Avatar>
            <div className={styles.number}>{mobileNumber}</div>
          </div>

          <div className={styles.tabContainer}>
            {Tabs.map(({ title, key }) => (
              <div
                key={key}
                onClick={() => changeHandler(key)}
                className={cx(styles.tab, { [styles.activeTab]: key === tab })}
              >
                {title}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.main}>
          {tab === "profile" && <MyProfile />}
          {tab === "bookings" && <MyBookings />}
          {tab === "feedback" && <Feedback />}
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
};

export default MyAccount;
