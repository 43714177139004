import * as React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentStatus, updatePaymentStatusSuper } from "../../dux/bookingRecords";
import { formatDate } from "../../helpers/dateHelper";
import { fetchBookingRecord } from "../../dux/bookingRecords";
import { isAuthorized, getRole } from "../../helpers/sessionStorageHelper";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function CustomizedSelects({
  defaultStatus,
  bookingId,

  handler,
}) {
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = React.useState(defaultStatus);
  const timerRef = React.useRef(null);

  const onChange = (e) => {
    setPaymentStatus(e.target.value);
    isAuthorized("SUPER_ADMIN") && dispatch(updatePaymentStatusSuper({ bookingId, status: e.target.value }));
    !isAuthorized("SUPER_ADMIN") && dispatch(updatePaymentStatus({ bookingId, status: e.target.value }));
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      handler();
    }, 500);
  };

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  if (defaultStatus === "SUCCESS") return <Typography>SUCCESS</Typography>;
  if (defaultStatus === "FULLY_PAID" && !isAuthorized("SUPER_ADMIN"))
    return <Typography>FULLY_PAID</Typography>;
  return (
    <FormControl variant="standard">
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={paymentStatus}
        input={<BootstrapInput />}
        onChange={onChange}
      >
        <MenuItem
          value="PARTIAL"
          // disabled={
          //   defaultStatus == "FULLY_PAID" && isAuthorized("SUPER_ADMIN")
          //     ? false
          //     : true
          // }
        >
          PARTIAL
        </MenuItem>
        <MenuItem value="FULLY_PAID">FULLY PAID</MenuItem>
      </Select>
    </FormControl>
  );
}
