import ResponsiveAppBar from "./components/AppBar";
import MainComponent from "../src/components/Main/Main";
import AboutUs from "../src/components/AboutUs/AboutUs";
import Facilities from "../src/components/Facilities/Facilities";
import Events from "../src/components/Events/Events";
import Gallery from "../src/components/Gallery/Gallery";
import BookNow from "../src/components/BookNow/BookNow";
import BlockSlot from "../src/components/BlockSlot/BlockSlot";
import MarkDateOffHoliday from "../src/components/MarkDateOffHoliday/MarkDateOffHoliday";
import BookingRecords from "../src/components/BookingRecords/BookingRecords";
import ContactUs from "./components/ContactUs/ContactUs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PromptHandler from "./components/Prompts/PromptHandler";

import { loggedIn } from "./helpers/sessionStorageHelper";
import { useDispatch, useSelector } from "react-redux";
import { logInUser } from "./dux/login";
import AdminPortal from "./components/AdminPortal/AdminPortal";
import MyAccount from "./components/MyAccount/MyAccount";
import Snackbar from "./components/shared/UI/Snackbar/Snackbar";
import RefundPolicy from "./components/OtherLinks/RefundPolicy";
import PrivacyPolicy from "./components/OtherLinks/PrivacyPolicy";
import TermsAndConditions from "./components/OtherLinks/TermsAndConditions";
import { shouldShowPrompt } from "./dux/prompt";

function App() {
  const dispatch = useDispatch();
  const showPrompt = useSelector(shouldShowPrompt);
  if (loggedIn()) {
    dispatch(logInUser());
  }
  return (
    <div className="main-container">
      <Router>
        {showPrompt && <PromptHandler />}
        <Snackbar />
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<MainComponent />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/book-now" element={<BookNow />} />
          <Route path="/block-slots" element={<BlockSlot />} />
          <Route
            path="/mark-date-off-or-holiday"
            element={<MarkDateOffHoliday />}
          />
          <Route path="/booking-records" element={<BookingRecords />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/my-account/:tab" element={<MyAccount />} />
          <Route path="/admin-portal/:tab" element={<AdminPortal />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
