import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import OtpInputBox from "./OtpInputBox";
import { Input } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { hidePrompt } from "../../../dux/prompt";
import { registerUser, sendMobileNumber, sendOtp } from "../../../dux/login";
import {
  isGetOtpPending,
  isRegisterUserPending,
  isVerifyOtpPending,
} from "../../../dux/apiStatus";
import LoaderForButton from "../UI/LoaderForButton";
import styles from "./LoginView.module.css";

const LoginView = ({
  userExistFlag,
  userValid,
  registered,
  otpCorrect,
  otpSent,
}) => {
  const dispatch = useDispatch();
  const [phoneNo, setphoneNo] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [otp, setOtp] = useState("");
  const isGetOtpLoading = useSelector(isGetOtpPending);
  const isVerifyOtpLoading = useSelector(isVerifyOtpPending);
  const isRegisterUserLoading = useSelector(isRegisterUserPending);

  const phoneChangeHandler = ({ target: { value } }) => {
    if (value.length <= 10) setphoneNo(value);
  };

  const fnameChangeHandler = ({ target: { value } }) => {
    setfname(value);
  };

  const lnameChangeHandler = ({ target: { value } }) => {
    setlname(value);
  };

  const emailChangeHandler = ({ target: { value } }) => {
    setemail(value);
  };

  const otpChangeHandler = (value) => {
    setOtp(value);
  };

  const sendOtpClickHandler = () => {
    dispatch(sendMobileNumber({ mobileNumber: phoneNo }));
  };

  const registerClickHandler = () => {
    dispatch(
      registerUser({
        firstName: fname,
        lastName: lname,
        emailAddress: email,
        mobileNumber: phoneNo,
      })
    );
  };

  const verifyOtpClickHandler = () => {
    dispatch(sendOtp({ mobileNumber: phoneNo, mobileOtp: otp }));
  };

  return (
    <Modal>
      <div className={styles.container}>
        <span className={styles.close} onClick={() => dispatch(hidePrompt())}>
          <CancelIcon
            sx={{
              height: 40,
              width: 40,
            }}
          />
        </span>
        <div className={styles.mobileInput}>
          {registered && (
            <div className={styles.text}>
              You have registered successfully. Please login
            </div>
          )}
          {userExistFlag && (
            <div className={styles.error}>User already exist.</div>
          )}
          <div className={styles.text}>Enter Your Mobile Number</div>
          <Input
            value={phoneNo}
            type="number"
            onChange={phoneChangeHandler}
            className={styles.inputField}
            placeholder="Mobile No"
            disabled={!userValid}
          />
          {!userValid && (
            <>
              <div className={styles.text}>
                Looks like you are new user. Please enter your details!
              </div>
              <Input
                value={fname}
                onChange={fnameChangeHandler}
                className={styles.inputField}
                placeholder="First Name"
              />
              <Input
                value={lname}
                onChange={lnameChangeHandler}
                className={styles.inputField}
                placeholder="Last Name"
              />
              <Input
                type="email"
                value={email}
                onChange={emailChangeHandler}
                className={styles.inputField}
                placeholder="Email"
              />
            </>
          )}
          {userValid && (
            <button
              disabled={isGetOtpLoading || phoneNo.length < 10}
              className={styles.btn}
              onClick={sendOtpClickHandler}
              key="login-btn"
            >
              {isGetOtpLoading ? <LoaderForButton /> : "SEND OTP"}
            </button>
          )}
          {!userValid && (
            <button
              disabled={
                isRegisterUserLoading ||
                fname === "" ||
                lname === "" ||
                email === ""
              }
              className={styles.btn}
              onClick={registerClickHandler}
              key="login-btn"
            >
              {isRegisterUserLoading ? <LoaderForButton /> : "REGISTER USER"}
            </button>
          )}
          {otpSent && (
            <span className={styles.resend} onClick={sendOtpClickHandler}>
              Resend OTP
            </span>
          )}
        </div>
        {!otpCorrect && (
          <div className={styles.error}>Incorrect OTP. Please re-enter.</div>
        )}
        {otpSent && (
          <div className={styles.otp}>
            <div className={styles.text}>OTP sent on Mobile No. & Email.</div>
            <div className={styles.text}>Enter 6-digit OTP</div>
            <OtpInputBox value={otp} changeHandler={otpChangeHandler} />
            <button
              className={styles.btn}
              onClick={verifyOtpClickHandler}
              disabled={isVerifyOtpLoading || otp.length < 6}

            >
              {isVerifyOtpLoading ? <LoaderForButton /> : "Verify"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

LoginView.propTypes = {
  errorFlag: PropTypes.bool,
  label: PropTypes.string,
  inputBoxPlaceholder: PropTypes.string,
  inputBoxValue: PropTypes.string,
  inputBoxChangeHandler: PropTypes.func,
  errorMsg: PropTypes.string,
  isOkBtnDisabled: PropTypes.bool,
  okBtnClickHandler: PropTypes.func,
  cancelBtnClickHandler: PropTypes.func,
};

export default LoginView;
