export const FETCH_MARK_DAYS_OFF_HOLIDAY = "FETCH_MARK_DAYS_OFF_HOLIDAY";
export const MARK_DAYS_OFF_HOLIDAY = "MARK_DAYS_OFF_HOLIDAY";
export const SET_MARK_DAYS_OFF_HOLIDAY = "SET_MARK_DAYS_OFF_HOLIDAY";

export const fetchMarkDaysOffHoliday = ({ sport }) => ({
  type: FETCH_MARK_DAYS_OFF_HOLIDAY,
  payload: { sport },
});

export const markDaysOffHoliday = ({ dateArr, type, sport }) => ({
  type: MARK_DAYS_OFF_HOLIDAY,
  payload: { dateArr, type, sport },
});

export const setMarkDaysOffHoliday = (payload) => ({
  type: SET_MARK_DAYS_OFF_HOLIDAY,
  payload,
});

const initialState = {
  1: [],
  2: [],
};

const markDaysOffHolidayReducer = (state = initialState, action) => {
  if (action.type === SET_MARK_DAYS_OFF_HOLIDAY) {
    return {
      ...action.payload,
    };
  }

  return state;
};

export default markDaysOffHolidayReducer;

export const getMarkDays = ({ markDays }) => markDays;
