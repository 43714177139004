import { API_ERROR } from "../../dux/api";
import { REGISTER_USER, SEND_MOBILE_NUMBER, SEND_OTP } from "../../dux/login";
import { showPrompt } from "../../dux/prompt";
import { promptCategoryType } from "../../helpers/constants";

const apiError = () => (next) => (action) => {
  if (action.type.endsWith(API_ERROR)) {
    const message = action.payload.data?.response ?? "";

    switch (action.type) {
      case `${SEND_MOBILE_NUMBER} ${API_ERROR}`:
      case `${SEND_OTP} ${API_ERROR}`:
      case `${REGISTER_USER} ${API_ERROR}`:
        next(action);
        break;

      default:
        next(
          showPrompt({
            category: promptCategoryType.GENERIC_ERROR,
            data: { message, status: action.payload.status },
          })
        );
        break;
    }
  } else {
    next(action);
  }
};

export default apiError;
