import axios from "axios";
import { API_REQUEST, apiSuccess, apiError } from "../../dux/api";
import { getAccessToken } from "../../helpers/sessionStorageHelper";
import { v4 } from "uuid";

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (!action.type.includes(API_REQUEST)) return;

    const { url, method, data, headers, feature, meta, responseType } =
      action.payload;

    const dataOrParams = ["GET"].includes(method) ? "params" : "data";

    // axios default configs
    axios.defaults.baseURL = process.env.BASE_API_URLS || "";

    axios
      .request({
        url: `${url}?access_token=${getAccessToken()?.access_token}`,
        method,
        headers: {
          ...headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()?.access_token}`,
        },
        responseType,
        [dataOrParams]: data,
      })
      .then((axiosResponse) => {
        if (axiosResponse.status === 200 && responseType === "blob") {
          const blob = new Blob([axiosResponse.data], { type: responseType });
          const url = URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement("a");
          link.href = url;
          link.download =
            feature == "INVOICE_DOWNLOAD"
              ? `invoice-${v4()}.pdf`
              : feature == "DOWNLOAD_BOOKING_RECORD_CSV"
              ? `output.csv`
              : "output";

          // Append the link to the body and click it to trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up: remove the link and revoke the object URL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else if (url.includes("invoice")) {
          // Handle error response
          console.error(
            "File download failed:",
            axiosResponse.status,
            axiosResponse.statusText
          );
        }
        dispatch(
          apiSuccess({
            response: axiosResponse.data,
            feature,
            meta,
          })
        );
      })
      .catch((error) => {
        dispatch(
          apiError({
            error,
            response: error.response,
            feature,
            meta,
          })
        );
      });
    // }
  };

export default apiMiddleware;
