import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, Button } from "antd";
import Highlighter from "react-highlight-words";
import SearchPanel from "./SearchPanel";
import { onFilter } from "../../helpers/bookingRecordsHelper";
import Calendar from "../shared/Calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBookingRecord,
  getBookingRecords,
  downloadInvoice,
  downloadCsv,
} from "../../dux/bookingRecords";
import { formatDate } from "../../helpers/dateHelper";
import { themeStyles } from "../../common/styles";
import styles from "./BookingRecords.module.css";
import CustomizedSelects from "./customizedSelect.button";
import moment from "moment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { showPrompt } from "../../dux/prompt";
import { promptCategoryType } from "../../helpers/constants";
import {
  DownloadDoneRounded,
  EditAttributesRounded,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { isAuthorized } from "../../helpers/sessionStorageHelper";

const { Column } = Table;

const BookingRecords = () => {
  const dispatch = useDispatch();
  const [phoneNo, setphoneNo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState(dayjs());
  const [tillDate, setTillDate] = useState(dayjs());
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);
  const list = useSelector(getBookingRecords);

  const [sortField, setSortField] = React.useState("playingTimestamp");

  const handleChange = (sortField) => {
    setSortField(sortField.target.value);
    dispatch(
      fetchBookingRecord({
        startDate: fromDate && formatDate(fromDate.format(), 3),
        endDate: tillDate && formatDate(tillDate.format(), 3),
        mobileNumber: phoneNo,
        sortField: sortField.target.value,
      })
    );
  };

  const phoneChangeHandler = ({ target: { value } }) => {
    if (value.length <= 10) setphoneNo(value);
  };

  const searchClickHandler = () => {
    dispatch(
      fetchBookingRecord({
        startDate: fromDate && formatDate(fromDate.format(), 3),
        endDate: tillDate && formatDate(tillDate.format(), 3),
        mobileNumber: phoneNo,
        sortField: sortField,
      })
    );
  };

  const downloadCsvHandler = () => {
    dispatch(
      downloadCsv({
        startDate: fromDate && formatDate(fromDate.format(), 3),
        endDate: tillDate && formatDate(tillDate.format(), 3),
        mobileNumber: phoneNo,
        sortField: sortField,
      })
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <SearchPanel
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        handleSearch={handleSearch}
        dataIndex={dataIndex}
        searchInput={searchInput}
        handleReset={handleReset}
        setSearchText={setSearchText}
        setSearchedColumn={setSearchedColumn}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => onFilter(dataIndex, value, record),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    dispatch(
      fetchBookingRecord({
        startDate: fromDate && formatDate(fromDate.format(), 3),
        endDate: tillDate && formatDate(tillDate.format(), 3),
        mobileNumber: phoneNo,
        sortField: sortField,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const handleDownload = (record) => {
    dispatch(downloadInvoice({ id: record.bookingId }));
  };

  const handleEdit = (totalAmount, bookingId) => {
    // Replace ruppe sign from totalAmount
    let total_amount_dec = parseInt(totalAmount.split(" ")[0]);
    dispatch(
      showPrompt({
        category: promptCategoryType.BOOKING_EDIT,
        data: {
          total_amount: total_amount_dec,
          bookingId: bookingId,
          fromDate: formatDate(fromDate.format(), 3),
          tillDate: formatDate(tillDate.format(), 3),
          phoneNo,
          sortField,
        },
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <div className={styles.calendarContainer}>
          <span className={styles.text}>From Date:</span>
          <div className={styles.calendar}>
            <Calendar date={fromDate} setDate={setFromDate} />
          </div>
        </div>
        <div className={styles.calendarContainer}>
          <span className={styles.text}>To Date:</span>
          <div className={styles.calendar}>
            <Calendar date={tillDate} setDate={setTillDate} />
          </div>
        </div>
        <div className={styles.mobileContainer}>
          <span className={styles.text}>Enter Mobile No:</span>
          <Input
            value={phoneNo}
            type="number"
            onChange={phoneChangeHandler}
            className={styles.inputField}
            placeholder="Mobile No"
          />
        </div>
        <div className={styles.searchBtnContainer}>
          <ToggleButtonGroup
            color="primary"
            value={sortField}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="bookingTimestamp"
              style={{ fontSize: "8.5px", height: "3 rem" }}
              // onClick={searchClickHandler}
            >
              Booking Date
            </ToggleButton>
            <ToggleButton
              value="playingTimestamp"
              style={{ fontSize: "8.5px", height: "3 rem" }}
              // onClick={searchClickHandler}
            >
              Playing Date
            </ToggleButton>
          </ToggleButtonGroup>
          <button
            className={styles.btn}
            onClick={searchClickHandler}
            style={{
              backgroundColor: themeStyles.secondaryColor,
              color: themeStyles.secondaryTextColor,
            }}
          >
            Search
          </button>
          <button
            className={styles.btn}
            onClick={downloadCsvHandler}
            style={{
              backgroundColor: themeStyles.tertiaryColor,
              color: themeStyles.secondaryTextColor,
            }}
          >
            Download CSV
          </button>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          bordered={false}
          dataSource={list}
          id="bookingId"
          pagination={false}
          rowKey="bookingId"
          size="small"
          scroll={{
            y:
              windowDimenion.winWidth > 899
                ? windowDimenion.winHeight - 210
                : windowDimenion.winHeight - 310,
          }}
        >
          <Column
            className={styles.rowCount}
            title="No."
            key="rowCount"
            fixed={windowDimenion.winWidth > 768}
            width={50}
            render={(text, record, index) => index + 1}
          />
          <Column
            className={styles.bookingId}
            title="Booking Id"
            dataIndex="bookingId"
            fixed={windowDimenion.winWidth > 768}
            width={180}
            {...getColumnSearchProps("bookingId")}
          />
          <Column
            className={styles.name}
            title="Customer Name"
            dataIndex="name"
            fixed={windowDimenion.winWidth > 768}
            width={250}
            {...getColumnSearchProps("name")}
          />
          <Column
            className={styles.transactionId}
            title="Transaction Id"
            dataIndex="transactionId"
            width={200}
            {...getColumnSearchProps("transactionId")}
          />
          <Column
            className={styles.mobileNo}
            title="Contact No"
            dataIndex="mobileNo"
            width={140}
            {...getColumnSearchProps("mobileNo")}
          />
          <Column
            className={styles.turfNo}
            title="Sport"
            dataIndex="sport"
            width={100}
          />
          <Column
            className={styles.turfNo}
            title="Turf"
            dataIndex="turf"
            width={50}
          />
          <Column
            className={styles.bookingDate}
            title="Booking Date"
            dataIndex="bookingDate"
            width={100}
          />
          <Column
            className={styles.playingDate}
            title="Playing Date"
            dataIndex="playingDate"
            width={100}
          />
          <Column
            className={styles.startTime}
            title="Start Time"
            dataIndex="startTime"
            width={100}
          />
          <Column
            className={styles.duration}
            title="Duration"
            dataIndex="duration"
            width={80}
          />
          <Column
            className={styles.totalAmount}
            title="Total Amount"
            dataIndex="totalAmount"
            width={100}
          />
          <Column
            className={styles.amountPaid}
            title="Amount Paid"
            dataIndex="amountPaid"
            width={100}
          />
          <Column
            className={styles.remainingAmount}
            title="Remaining Amount"
            dataIndex="remainingAmount"
            width={100}
          />
          <Column
            className={styles.bookingStatus}
            title="Booking Status"
            width={110}
            render={(text, record) => (
              <CustomizedSelects
                defaultStatus={record.bookingStatus}
                bookingId={record.bookingId}
                handler={searchClickHandler}
              />
            )}
          />
          <Column
            className={styles.download}
            title="Invoice Download"
            dataIndex=""
            key="download"
            width={100}
            render={(text, record) => (
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadDoneRounded />}
                onClick={() => handleDownload(record)}
              />
            )}
          />

          {isAuthorized("SUPER_ADMIN") && (
            <Column
              className={styles.download}
              title="Edit Total Amount"
              dataIndex=""
              key="edit"
              width={100}
              render={(text, record) => (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditAttributesRounded />}
                  onClick={() =>
                    handleEdit(record.totalAmount, record.bookingId)
                  }
                  className={styles.editButton}
                />
              )}
            />
          )}
        </Table>
      </div>
    </div>
  );
};

export default BookingRecords;
