export const FETCH_BOOKING_RECORD = "FETCH_BOOKING_RECORD";
export const EDIT_BOOKING_RECORD = "EDIT_BOOKING_RECORD";
export const DOWNLOAD_BOOKING_RECORD_CSV = "DOWNLOAD_BOOKING_RECORD_CSV";
export const SET_BOOKING_RECORD = "SET_BOOKING_RECORD";
export const RESET_BOOKING_RECORD = "RESET_BOOKING_RECORD";
export const INVOICE_DOWNLOAD = "INVOICE_DOWNLOAD";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const UPDATE_PAYMENT_STATUS_SUPER = "UPDATE_PAYMENT_STATUS_SUPER";

export const bookingStatusChange = "bookingStatusChange";

export const editBookingRecord = ({ bookingId, totalAmount }) => ({
  type: EDIT_BOOKING_RECORD,
  payload: { bookingId, totalAmount },
});

export const fetchBookingRecord = ({
  startDate,
  endDate,
  mobileNumber,
  sortField,
} = {}) => ({
  type: FETCH_BOOKING_RECORD,
  payload: { startDate, endDate, mobileNumber, sortField },
});

export const setBookingRecord = ({ list }) => ({
  type: SET_BOOKING_RECORD,
  payload: { list },
});

export const resetBookingRecord = () => ({
  type: RESET_BOOKING_RECORD,
});

const initialState = {
  list: [],
};

const bookingRecords = (state = initialState, action) => {
  if (action.type === SET_BOOKING_RECORD) {
    return {
      ...action.payload,
    };
  }

  if (action.type === RESET_BOOKING_RECORD) {
    return initialState;
  }
  return state;
};

export default bookingRecords;

export const getBookingRecords = ({ bookingRecords: { list } }) => list;

export const downloadInvoice = ({ id }) => ({
  type: INVOICE_DOWNLOAD,
  payload: { id },
});

export const updatePaymentStatus = (data) => ({
  type: UPDATE_PAYMENT_STATUS,
  payload: data,
});

export const updatePaymentStatusSuper = (data) => ({
  type: UPDATE_PAYMENT_STATUS_SUPER,
  payload: data,
});

export const downloadCsv = ({
  startDate,
  endDate,
  mobileNumber,
  sortField,
} = {}) => ({
  type: DOWNLOAD_BOOKING_RECORD_CSV,
  payload: { startDate, endDate, mobileNumber, sortField },
});
