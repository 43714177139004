import React from "react";
import { useDispatch } from "react-redux";
import { hidePrompt } from "../../../dux/prompt";
import { destroySession } from "../../../helpers/sessionStorageHelper";
import Modal from "../../shared/Modal/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { logOutUser } from "../../../dux/login";
import { useNavigate } from "react-router-dom";
import styles from "./GenericError.module.css";

const GenericError = ({ message, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeHandler = () => {
    dispatch(hidePrompt());
    if (status == 999) {
      dispatch(logOutUser());
      destroySession();
      navigate("/");
    }
  };

  return (
    <Modal>
      <div className={styles.container}>
        <span className={styles.close} onClick={closeHandler}>
          <CancelIcon
            sx={{
              height: 40,
              width: 40,
            }}
          />
        </span>
        <h1 className={styles.title}>Oops! There is a problem...</h1>
        <p className={styles.content}>
          {message
            ? message
            : "There seems to be a problem. The system is unable to process your request. Please try again in some time."}
        </p>
      </div>
    </Modal>
  );
};

export default GenericError;
