import React, { useEffect, useCallback } from "react";
import cx from "classnames";
import { fetchMyBookings, getMyBookings } from "../../../dux/myBookings";
import { useDispatch, useSelector } from "react-redux";
import { getActiveTab, setTab } from "../../../dux/myBookingTab";
import styles from "./MyBookings.module.css";
import throttle from "lodash.throttle";

const Tabs = [
  { title: "PAST", key: "past" },
  { title: "TODAY", key: "today" },
  { title: "UPCOMING", key: "upcoming" },
];

const MyBookings = () => {
  const dispatch = useDispatch();
  const bookings = useSelector(getMyBookings);
  const activeTab = useSelector(getActiveTab);

  useEffect(() => {
    dispatch(fetchMyBookings());
  }, []);

  const throttledFetchMyBookings = useCallback(
    throttle(() => {
      dispatch(fetchMyBookings());
    }, 1500),
    [dispatch]
  );

  const clickHandler = (key) => {
    dispatch(setTab({ tab: key }));
    throttledFetchMyBookings();
  };

  const timeFormat = (timeString) => {
    const timeString12hr = new Date(
      "1970-01-01T" + timeString + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });

    return timeString12hr;
  };

  return (
    <div>
      <div className={styles.tabGroup}>
        {Tabs.map(({ title, key }) => (
          <div
            key={key}
            className={cx(styles.tab, {
              [styles.activeTab]: activeTab === key,
            })}
            onClick={() => clickHandler(key)}
          >
            {title}
          </div>
        ))}
      </div>

      <div className={styles.booknigContainer}>
        {bookings[activeTab].map(
          ({
            bookingId,
            transactionId,
            turf,
            sport,
            playingDate,
            startTime,
            duration,
            amount,
            remainingAmount,
            bookingStatus,
          }) => (
            <div className={styles.card}>
              <div className={styles.title}>
                {`${playingDate} ${timeFormat(startTime)} (${duration} hours)`}
                <span>
                  {sport == "Cricket Net"
                    ? turf === 1
                      ? "Net A"
                      : turf === 2
                      ? "Net B"
                      : "Net C"
                    : sport == "Pickle Ball"
                    ? turf === 1
                      ? "Court A"
                      : turf === 2
                      ? "Court B"
                      : "Court C"
                    : turf === 1
                    ? "5 a side turf A"
                    : turf === 2
                    ? "5 a side turf B"
                    : "7 a side turf C"}
                </span>
              </div>
              <div className={styles.sportInfo}>
                <span>{`Sport- ${sport}`}</span>
              </div>
              <div className={styles.info}>
                <span>{`Amount Paid- ${amount} ₹`}</span>
                <span>{`Remaining Amount- ${remainingAmount} ₹`}</span>
                <span>{`Status- ${bookingStatus}`}</span>
              </div>
              <div className={styles.info}>
                <span>{`TransactionId- ${transactionId}`}</span>
                <span>{`BookingId- ${bookingId}`}</span>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MyBookings;
