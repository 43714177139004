import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCallComplete, isSendFeedbackPending } from "../../../dux/apiStatus";
import { sendFeedback } from "../../../dux/feedback";
import LoaderForButton from "../../shared/UI/LoaderForButton";
import styles from "./Feedback.module.css";

const Feedback = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    return () => {
      dispatch(apiCallComplete({ api: "sendFeedback" }));
    };
  }, []);

  const submitHandler = () => {
    dispatch(sendFeedback({ feedback: value }));
    setValue("");
  };

  const isLoading = useSelector(isSendFeedbackPending);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Please tell us what do you think, any kind of feedback is highly
        appreciated.
      </div>
      <div className={styles.textarea}>
        <TextArea
          value={value}
          style={{ borderColor: "#aaaaaa" }}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Please write here!"
          autoSize={{
            minRows: 10,
            maxRows: 15,
          }}
        />
      </div>
      <button
        className={styles.btn}
        onClick={submitHandler}
        disabled={!value || isLoading}
      >
        {isLoading ? <LoaderForButton /> : "SUBMIT"}
      </button>
    </div>
  );
};

export default Feedback;
