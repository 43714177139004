import { message as antMessage } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, getSnackbarData } from "../../../../dux/snackbar";
import { snackBarStatus, SNACKBAR_TIME } from "../../../../helpers/constants";
import styles from "./Snackbar.module.css";

const { SUCCESS, ERROR } = snackBarStatus;

const iconClass = {
  [SUCCESS]: styles.successIcon,
  [ERROR]: styles.errorIcon,
};

const Snackbar = () => {
  const dispatch = useDispatch();
  const { message, status = SUCCESS } = useSelector(getSnackbarData);

  useEffect(() => {
    if (message === "") return;

    antMessage.open({
      content: (
        <div className={styles.snackBarContent}>
          <div className={iconClass[status]} />
          <p>{message}</p>
        </div>
      ),
      className: styles.snackBar,
      duration: SNACKBAR_TIME,
      onClose: () => {
        dispatch(closeSnackbar());
      },
    });
  }, [message, dispatch, status]);
};

export default Snackbar;
