import React from "react";
import { Spin } from "antd";

const Loader = ({ size }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size={size} />
    </div>
  );
};

export default Loader;
