import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import { Input } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { hidePrompt } from "../../../dux/prompt";
import styles from "./BookingEditView.module.css";
import {
  editBookingRecord,
  fetchBookingRecord,
} from "../../../dux/bookingRecords";

const BookingEditView = ({ bookingId, total_amount, fromDate, tillDate, phoneNo, sortField }) => {
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(total_amount);

  const totalAmountChangeHandler = ({ target: { value } }) => {
    if (value.length <= 10) setTotalAmount(value);
  };

  const editBooking = (id) => {
    dispatch(
      editBookingRecord({
        bookingId: id,
        totalAmount: totalAmount,
      })
    );
    dispatch(hidePrompt());

    setTimeout(() => {
      dispatch(
        fetchBookingRecord({
          startDate: fromDate,
          endDate: tillDate,
          mobileNumber: phoneNo,
          sortField: sortField,
        })
      );
    }, 1500);
  };

  return (
    <Modal>
      <div className={styles.container}>
        <span className={styles.close} onClick={() => dispatch(hidePrompt())}>
          <CancelIcon
            sx={{
              height: 40,
              width: 40,
            }}
          />
        </span>
        <div className={styles.mobileInput}>
          <div className={styles.text}>Enter new amount</div>
          <Input
            value={totalAmount}
            type="number"
            onChange={totalAmountChangeHandler}
            className={styles.inputField}
            placeholder="Total Amount"
          />

          <button
            disabled={totalAmount.length < 1}
            className={styles.btn}
            onClick={() => editBooking(bookingId)}
            key="edit-booking-btn"
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

BookingEditView.propTypes = {
  errorFlag: PropTypes.bool,
  label: PropTypes.string,
  inputBoxPlaceholder: PropTypes.string,
  inputBoxValue: PropTypes.string,
  inputBoxChangeHandler: PropTypes.func,
  errorMsg: PropTypes.string,
  isOkBtnDisabled: PropTypes.bool,
  okBtnClickHandler: PropTypes.func,
  cancelBtnClickHandler: PropTypes.func,
};

export default BookingEditView;
