import { apiAction, API_ERROR, API_SUCCESS } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import {
  logInUser,
  REGISTER_USER,
  SEND_MOBILE_NUMBER,
  SEND_OTP,
  setOtpIncorrect,
  setOtpRecieved,
  setRegisteredFlag,
  setUserExist,
  setUserInvalid,
} from "../dux/login";
import { hidePrompt, showPrompt } from "../dux/prompt";
import { openSnackbar } from "../dux/snackbar";
import { promptCategoryType } from "../helpers/constants";
import { getBaseApiUrl } from "../helpers/generic";
import { startSession } from "../helpers/sessionStorageHelper";

const login = () => (next) => (action) => {
  next(action);

  // to get otp
  if (action.type === SEND_MOBILE_NUMBER) {
    next(apiCallStart({ api: "getOtp" }));
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/users/login-with-otp`,
        feature: SEND_MOBILE_NUMBER,
        method: "POST",
        data: { mobileNumber: action.payload.mobileNumber },
      })
    );
  }

  if (action.type === `${SEND_MOBILE_NUMBER} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "getOtp" }));
    next(setOtpRecieved());
  }

  if (action.type === `${SEND_MOBILE_NUMBER} ${API_ERROR}`) {
    next(apiCallComplete({ api: "getOtp" }));
    if (action?.payload?.data?.status === 404) {
      next(setUserInvalid());
    } else {
      next(showPrompt({ category: promptCategoryType.GENERIC_ERROR }));
    }
  }

  //verify otp
  if (action.type === SEND_OTP) {
    next(apiCallStart({ api: "verifyOtp" }));
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/users/verify-with-otp`,
        feature: SEND_OTP,
        method: "POST",
        data: {
          mobileNumber: action.payload.mobileNumber,
          mobileOtp: action.payload.mobileOtp,
        },
      })
    );
  }

  if (action.type === `${SEND_OTP} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "verifyOtp" }));
    startSession(JSON.stringify(action.payload.response));
    next(logInUser());
    next(hidePrompt());
    next(openSnackbar({ message: "User Logged In Successfully" }));
  }

  if (action.type === `${SEND_OTP} ${API_ERROR}`) {
    next(apiCallComplete({ api: "verifyOtp" }));
    if (action?.payload?.data?.status === 403) {
      next(setOtpIncorrect());
    } else {
      next(showPrompt({ category: promptCategoryType.GENERIC_ERROR }));
    }
  }

  if (action.type === REGISTER_USER) {
    next(apiCallStart({ api: "registerUser" }));
    const { firstName, lastName, emailAddress, mobileNumber } = action.payload;
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/users/register-user`,
        feature: REGISTER_USER,
        method: "POST",
        data: { firstName, lastName, emailAddress, mobileNumber },
      })
    );
  }

  if (action.type === `${REGISTER_USER} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "registerUser" }));
    next(setRegisteredFlag());
  }

  if (action.type === `${REGISTER_USER} ${API_ERROR}`) {
    next(apiCallComplete({ api: "registerUser" }));
    if (action?.payload?.data?.status === 403) {
      next(setUserExist());
    } else {
      next(showPrompt({ category: promptCategoryType.GENERIC_ERROR }));
    }
  }
};

export default login;
