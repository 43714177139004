import "./style.scss";
import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.jpeg";
import image3 from "../../assets/image3.jpg";
import image4 from "../../assets/image4.jpg";
import image5 from "../../assets/image5.jpg";
import image6 from "../../assets/image6.jpg";
import image7 from "../../assets/image7.jpg";
import image8 from "../../assets/image8.jpg";
import image9 from "../../assets/image9.jpg";
import image10 from "../../assets/image10.jpg";
import image11 from "../../assets/image11.jpg";
import image12 from "../../assets/image12.jpg";
import image13 from "../../assets/image13.jpg";
import image14 from "../../assets/image14.jpeg";
import image15 from "../../assets/image15.jpeg";
import image16 from "../../assets/image16.jpg";
import image17 from "../../assets/image17.jpeg";
import image18 from "../../assets/image18.jpeg";
import image19 from "../../assets/image19.jpeg";
import image20 from "../../assets/image20.jpeg";
import image21 from "../../assets/image21.jpeg";

import ImageGallery from "react-image-gallery";

const Gallery = () => {
  const images = [
    {
      original: image1,
    },
    {
      original: image2,
    },
    {
      original: image3,
    },
    {
      original: image4,
    },
    {
      original: image5,
    },
    {
      original: image6,
    },
    {
      original: image7,
    },
    {
      original: image8,
    },
    {
      original: image9,
    },
    {
      original: image10,
    },
    {
      original: image11,
    },
    {
      original: image12,
    },
    {
      original: image13,
    },
    {
      original: image14,
    },
    {
      original: image15,
    },
    {
      original: image16,
    },
    {
      original: image17,
    },
    {
      original: image18,
    },
    {
      original: image19,
    },
    {
      original: image20,
    },
    {
      original: image21,
    },
  ];
  return (
    <>
      <div className="gallery-wrapper">
        <ImageGallery
          items={images}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          lazyload={true}
          autoPlay={true}
          className="gallery-image"
        />
      </div>
      <div className="footer"></div>
    </>
  );
};

export default Gallery;
