export const SHOW_PROMPT = "[prompt] show";
export const HIDE_PROMPT = "[prompt] hide";

// actions
export const showPrompt = ({ category, data = {} }) => ({
  type: SHOW_PROMPT,
  payload: {
    category,
    data,
  },
});

export const hidePrompt = () => ({
  type: HIDE_PROMPT,
});

// reducer
const initialState = {
  show: false,
  category: "",
  data: {},
};

const promptReducer = (state = initialState, action) => {
  if (action.type === SHOW_PROMPT) {
    return {
      ...state,
      show: true,
      ...action.payload,
    };
  }

  if (action.type === HIDE_PROMPT) {
    return initialState;
  }

  return state;
};

export default promptReducer;

// selectors

export const shouldShowPrompt = ({ prompt: { show } }) => show;

export const getPromptData = ({ prompt: { category, data } }) => ({
  category,
  data,
});
