import { Paper, Typography } from "@mui/material";
import ImageGallery from "react-image-gallery";
import facilitiesImage from "../../assets/facilities-1.jpg";
import eventsImage from "../../assets/events.jpg";
import styles from "./Facilities.module.css";

const Facilities = () => {
  const images = [
    {
      original: facilitiesImage,
    },
    {
      original: eventsImage,
    },
  ];
  return (
    <div className={styles.container}>
      <div className="main-wrapper">
        <div className="background">
          <div className="gallery-wrapper">
            <ImageGallery
              items={images}
              showFullscreenButton={false}
              showPlayButton={false}
              showThumbnails={false}
              lazyload={true}
              autoPlay={true}
              className="gallery-image"
            />
          </div>
        </div>

        <div className="white-paper">
          <Paper
            style={{
              width: "88%",
              position: "relative",
              top: "-15px",
              height: "calc(100% + 15px)",
              padding: "20px 32px 20px 32px",
              boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.09)",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              style={{ color: "#01245a", marginBottom: "20px" }}
            >
              <strong>Facilities</strong>
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#797979", fontSize: "14px" }}
            >
              We have well maintained washrooms for Men and Women, Water
              dispenser, Audience Seating, Stadium Lighting & we have plenty of
              parking available.
            </Typography>
          </Paper>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default Facilities;
