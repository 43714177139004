import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "antd";
import { getUser } from "../../../helpers/sessionStorageHelper";
import { saveProfile } from "../../../dux/profile";
import { apiCallComplete, isSaveProfilePending } from "../../../dux/apiStatus";
import styles from "./MyProfile.module.css";
import LoaderForButton from "../../shared/UI/LoaderForButton";

const MyProfile = () => {
  const {
    firstName = "",
    lastName = "",
    emailAddress = "",
    mobileNumber,
  } = getUser();

  const dispatch = useDispatch();
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const isSavingProfile = useSelector(isSaveProfilePending);

  const reset = () => {
    setfname(firstName);
    setlname(lastName);
    setemail(emailAddress);
  };

  const saveHandler = () => {
    dispatch(
      saveProfile({
        firstName: fname,
        lastName: lname,
        emailAddress: email,
        mobileNumber,
      })
    );
  };

  useEffect(() => {
    reset();

    return () => {
      dispatch(apiCallComplete({ api: "saveProfile" }));
    };
  }, []);

  const fnameChangeHandler = ({ target: { value } }) => {
    setfname(value);
  };

  const lnameChangeHandler = ({ target: { value } }) => {
    setlname(value);
  };

  const emailChangeHandler = ({ target: { value } }) => {
    setemail(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <Avatar
          icon={<PersonRoundedIcon style={{ width: 200, height: 200 }} />}
          style={{ width: 200, height: 200 }}
        >
          J
        </Avatar>

        <div className={styles.number}>{mobileNumber}</div>
      </div>
      <Input
        value={fname}
        onChange={fnameChangeHandler}
        className={styles.inputField}
        placeholder="First Name"
      />
      <Input
        value={lname}
        onChange={lnameChangeHandler}
        className={styles.inputField}
        placeholder="Last Name"
      />
      <Input
        type="email"
        value={email}
        onChange={emailChangeHandler}
        className={styles.inputField}
        placeholder="Email"
      />

      <div className={styles.btnContainer}>
        <button
          className={styles.savebtn}
          onClick={saveHandler}
          disabled={!(email && fname && lname) || isSavingProfile}
        >
          {isSavingProfile ? <LoaderForButton /> : "SAVE"}
        </button>

        <button className={styles.btn} onClick={reset}>
          RESET
        </button>
      </div>
    </div>
  );
};

export default MyProfile;
