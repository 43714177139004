const PREFIX = "apiStatus";
export const API_CALL_START = `${PREFIX}/apiCallStart`;
export const API_CALL_COMPLETE = `${PREFIX}/apiCallComplete`;

// actions
export const apiCallStart = ({ api }) => ({
  type: API_CALL_START,
  payload: { api },
});

export const apiCallComplete = ({ api }) => ({
  type: API_CALL_COMPLETE,
  payload: { api },
});

const initialState = {
  fetchMarkOffHoliday: false,
  markOffHoliday: false,
  sendFeedback: false,
  blockUnblock: false,
  verifyOtp: false,
  getOtp: false,
  registerUser: false,
  fetchBookNowDetail: false,
  newBooking: false,
  saveProfile: false,
  invoiceDownload: false,
  csvDownload: false,
  bookingStatusChange: false,
  editBooking: false,
  bookingSetting:false,
};

// reducer
const apiStatus = (state = initialState, action) => {
  if (action.type === API_CALL_START) {
    return {
      ...state,
      [action.payload.api]: true,
    };
  }

  if (action.type === API_CALL_COMPLETE) {
    return {
      ...state,
      [action.payload.api]: false,
    };
  }

  return state;
};

export default apiStatus;

// selectors
export const isSendFeedbackPending = ({ apiStatus: { sendFeedback } }) =>
  sendFeedback;

export const isGetOtpPending = ({ apiStatus: { getOtp } }) => getOtp;

export const isMarkOffHolidayPending = ({ apiStatus: { markOffHoliday } }) =>
  markOffHoliday;

export const isFetchMarkOffHolidayPending = ({
  apiStatus: { fetchMarkOffHoliday },
}) => fetchMarkOffHoliday;

export const isVerifyOtpPending = ({ apiStatus: { verifyOtp } }) => verifyOtp;

export const isRegisterUserPending = ({ apiStatus: { registerUser } }) =>
  registerUser;

export const isFetchBookNowPending = ({ apiStatus: { fetchBookNowDetail } }) =>
  fetchBookNowDetail;

export const isNewBookingPending = ({ apiStatus: { newBooking } }) =>
  newBooking;

export const isBlockUnblockPending = ({ apiStatus: { blockUnblock } }) =>
  blockUnblock;

export const isSaveProfilePending = ({ apiStatus: { saveProfile } }) =>
  saveProfile;

export const isInvoiceDownloadPending = ({ apiStatus: { invoiceDownload } }) =>
  invoiceDownload;

export const isBookingStatusChangePending = ({
  apiStatus: { bookingStatusChange },
}) => bookingStatusChange;
