import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCallComplete } from "../../../dux/apiStatus";
import {
  hasOtpBeenSent,
  isOtpIncorrect,
  isUserInvalid,
  registeredFlag,
  reset,
  userExist,
} from "../../../dux/login";
import LoginView from "./LoginView";

const Login = () => {
  const userExistFlag = useSelector(userExist);
  const userValid = !useSelector(isUserInvalid);
  const otpCorrect = !useSelector(isOtpIncorrect);
  const otpSent = useSelector(hasOtpBeenSent);
  const registered = useSelector(registeredFlag);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
    return () => {
      dispatch(apiCallComplete({ api: "verifyOtp" }));
      dispatch(apiCallComplete({ api: "getOtp" }));
      dispatch(apiCallComplete({ api: "registerUser" }));
    };
  }, []);

  return (
    <LoginView
      userExistFlag={userExistFlag}
      userValid={userValid}
      otpCorrect={otpCorrect}
      otpSent={otpSent}
      registered={registered}
    />
  );
};

Login.propTypes = {};

export default Login;
