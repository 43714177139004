import React from "react";
import OtpInput from "react-otp-input";

const OtpInputBox = ({ value, changeHandler }) => {
  return (
    <div>
      <OtpInput
        value={value}
        isInputNum
        onChange={changeHandler}
        numInputs={6}
        inputStyle={{
          width: "3rem",
          height: "3rem",
          margin: "0 2px",
          fontSize: "2rem",
          borderRadius: 4,
          border: "1px solid rgba(0,0,0,0.3)",
        }}
        separator={<span></span>}
      />
    </div>
  );
};

export default OtpInputBox;
