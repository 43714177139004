import moment from "moment";
import { convert24hourTo12hourTime } from "./dateHelper";

export const onFilter = () => (dataIndex, value, record) =>
  record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());

const parseSingleRecord = ({
  bookingId,
  transactionId,
  mobileNumber,
  firstName,
  lastName,
  turfNumber,
  slotDate,
  slotTime,
  duration,
  sport,
  bookingStatus,
  totalAmount,
  amount,
  remainingAmount,
  bookingTimestamp,
}) => ({
  bookingId,
  transactionId,
  name: `${firstName} ${lastName}`,
  mobileNo: mobileNumber,
  turf: turfNumber == 1 ? "A" : turfNumber == 2 ? "B" : "C",
  bookingDate: moment(new Date(bookingTimestamp)).format("DD/MM/yyyy"),
  playingDate: slotDate,
  startTime: convert24hourTo12hourTime(slotTime).toString(),
  duration,
  totalAmount: totalAmount + " ₹",
  amountPaid: amount + " ₹",
  remainingAmount: remainingAmount + " ₹",
  bookingStatus,
  sport,
});

export const parseBookingRecords = (list) =>
  list.map((i) => parseSingleRecord(i));
