import { apiAction, API_SUCCESS } from "../dux/api";
import { FETCH_MY_BOOKINGS, setMyBookings } from "../dux/myBookings";
import { parseMyBookings } from "../helpers/myBookingsHelper";
import { getBaseApiUrl } from "../helpers/generic";

const myBookings = () => (next) => (action) => {
  next(action);

  if (action.type === FETCH_MY_BOOKINGS) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/my-bookings`,
        feature: FETCH_MY_BOOKINGS,
      })
    );
  }

  if (action.type === `${FETCH_MY_BOOKINGS} ${API_SUCCESS}`) {
    const parsedData = parseMyBookings(action.payload);
    next(setMyBookings({ list: parsedData }));
  }
};

export default myBookings;
