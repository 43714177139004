import { Paper, Typography } from "@mui/material";
import MapComponent from "../Map/MapComponent/MapComponent";
import styles from "./ContactUs.module.css";
import whs_map from "../../assets/whs_map.png";
const ContactUs = () => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.background}>
      </div>
      <div className="main-wrapper">
        <div className="background">
          <img
            src={whs_map}
            alt="Map"
            loading="lazy"
            style={{ width: "100%", maxHeight:"500px", minHeight: "300px" }}
          />
        </div>
        <div className="white-paper">
          <Paper
            style={{
              width: "88%",
              position: "relative",
              top: "-15px",
              height: "calc(100% + 15px)",
              padding: "20px 32px 20px 32px",
              boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.09)",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              style={{ color: "#01245a", marginBottom: "20px" }}
            >
               <strong>Contact Us</strong>
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#797979", fontSize: "14px", marginBottom: "14px" }}
          >
            <strong>E-mail:</strong> contact@whssportshub.in
            <br />
            <strong>Address:</strong> WHS Sports Hub, Sonwar Bagh, Srinagar
            190001 (J&K)
            <br />
            <strong>Contact No.:</strong> +91 97977 33171
          </Typography>
          <div className={styles.socialMedia}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/WHSSportsHub"
            >
              <div className={styles.facebookIcon} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/whssportshub?igshid=YmMyMTA2M2Y="
            >
              <div className={styles.instaIcon} />
            </a>
          </div>
          </Paper>
        </div>
        <div className="footer"></div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
};

export default ContactUs;
