import { apiAction, API_ERROR, API_SUCCESS } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import { SEND_FEEDBACK } from "../dux/feedback";
import { openSnackbar } from "../dux/snackbar";
import { getBaseApiUrl } from "../helpers/generic";

const feedback = () => (next) => (action) => {
  next(action);

  if (action.type === SEND_FEEDBACK) {
    const { feedback } = action.payload;
    next(apiCallStart({ api: "sendFeedback" }));
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/users/feedback`,
        feature: SEND_FEEDBACK,
        method: "POST",
        data: { feedback },
      })
    );
  }

  if (action.type === `${SEND_FEEDBACK} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "sendFeedback" }));
    next(
      openSnackbar({
        message: "Feedback has submitted successfully.",
      })
    );
  }

  if (action.type === `${SEND_FEEDBACK} ${API_ERROR}`) {
    next(apiCallComplete({ api: "sendFeedback" }));
  }
};

export default feedback;
