import { openSnackbar, OPEN_SNACKBAR } from '../dux/snackbar';

const snackbar = () => (next) => (action) => {
  if (action.type === OPEN_SNACKBAR) {
    next(
      openSnackbar({
        message: action.payload.message,
        status: action.payload.status
      })
    );
  } else {
    next(action);
  }
};

export default snackbar;
