import dayjs from "dayjs";

export const NEW_BOOKING = "NEW_BOOKING";
export const FETCH_BOOK_NOW_DETAILS = "FETCH_BOOK_NOW_DETAILS";
export const SET_BOOK_NOW_DETAILS = "SET_BOOK_NOW_DETAILS";
export const RESET_BOOK_NOW_DETAILS = "RESET_BOOK_NOW_DETAILS";
export const SET_NEW_BOOKING_DATA = "SET_NEW_BOOKING_DATA";
export const VERIFY_PAYMENT = "VERIFY_PAYMENT";
export const MAKE_SLOT_AVAILABLE = "MAKE_SLOT_AVAILABLE";

export const fetchBookNowDetail = ({ date, sport } = {}) => ({
  type: FETCH_BOOK_NOW_DETAILS,
  payload: { date, sport },
});

export const newBooking = ({
  sport,
  emailAddress,
  slotDate,
  slotTime,
  duration,
  turfNumber,
  paidAmount,
  totalAmount,
}) => ({
  type: NEW_BOOKING,
  payload: {
    sport,
    emailAddress,
    slotDate,
    slotTime,
    duration,
    turfNumber,
    paidAmount,
    totalAmount,
  },
});
export const makeSlotAvailable = ({
  slotDate,
  slotTime,
  turfNumber,
  sport,
}) => ({
  type: MAKE_SLOT_AVAILABLE,
  payload: {
    slotDate,
    slotTime,
    turfNumber,
    sport,
  },
});

export const verifyPayment = ({
  razorpay_order_id,
  razorpay_payment_id,
  razorpay_signature,
  transactionId,
  sport,
  turfNumber,
  slotDate,
  slotTime,
  duration,
  paidAmount,
  totalAmount,
}) => ({
  type: VERIFY_PAYMENT,
  payload: {
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature,
    transactionId,
    sport,
    turfNumber,
    slotDate,
    slotTime,
    duration,
    paidAmount,
    totalAmount,
  },
});

export const setBookNowDetail = (data) => ({
  type: SET_BOOK_NOW_DETAILS,
  payload: { data },
});

export const setNewBookingData = (data) => ({
  type: SET_NEW_BOOKING_DATA,
  payload: { data },
});

export const resetBookNowDetail = () => ({
  type: RESET_BOOK_NOW_DETAILS,
});

const initialState = {
  date: dayjs(), // selsected date
  bookingAllowed: true, // If client has blocked booking of any turf this would be true otherwise false
  slotsAvailability: { turfA: [], turfB: [], turfC: [] },
  slots: { turfA: [], turfB: [], turfC: [] },
  newBooking: { order_id: "", transactionId: "", currency: "", amount: "" },
};

const bookNow = (state = initialState, action) => {
  if (action.type === SET_BOOK_NOW_DETAILS) {
    return { ...state, ...action.payload.data };
  }
  if (action.type === SET_NEW_BOOKING_DATA) {
    return {
      ...state,
      newBooking: { ...action.payload.data },
    };
  }

  if (action.type === RESET_BOOK_NOW_DETAILS) {
    return initialState;
  }
  return state;
};

export default bookNow;

export const getBookNowData = ({ bookNow }) => bookNow;
