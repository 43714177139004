import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import logo from "../assets/logo.png";
import { themeStyles } from "../common/styles";

import StyledLink from "../common/StyledLink";
import { useDispatch, useSelector } from "react-redux";
import { showPrompt } from "../dux/prompt";
import { promptCategoryType } from "../helpers/constants";
import { getLoginFlag, logOutUser } from "../dux/login";
import {
  destroySession,
  getName,
  isAuthorized,
} from "../helpers/sessionStorageHelper";
import LoginPanel from "./shared/LoginPanel/LoginPanel";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const pages = [
  { route: "Home", url: "/" },
  { route: "About us", url: "/about-us" },
  { route: "Facilities", url: "/facilities" },
  { route: "Events", url: "/events" },
  { route: "Gallery", url: "/gallery" },
  { route: "Contact", url: "/contact" },
];

const userPages = [
  ...pages,
  { route: "My Profile", url: "/my-account/profile" },
  { route: "My Bookings", url: "/my-account/bookings" },
  { route: "Feedback", url: "/my-account/feedback" },
];

const adminPages = [
  ...pages,
  { route: "My Profile", url: "/my-account/profile" },
  { route: "My Bookings", url: "/my-account/bookings" },
  { route: "All Booking", url: "/booking-records" },
  { route: "Feedback", url: "/my-account/feedback" },
  { route: "Block/Unblock Slots", url: "/block-slots" },
  { route: "Mark Days Off/Holiday", url: "/mark-date-off-or-holiday" },
];

const superAdminPages = [
  ...pages,
  { route: "My Profile", url: "/my-account/profile" },
  { route: "My Bookings", url: "/my-account/bookings" },
  { route: "All Booking", url: "/booking-records" },
  { route: "Feedback", url: "/my-account/feedback" },
  { route: "Block/Unblock Slots", url: "/block-slots" },
  { route: "Mark Days Off/Holiday", url: "/mark-date-off-or-holiday" },
  { route: "Rate Setting", url: "/admin-portal/general-rates" },
];
const ResponsiveAppBar = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoginFlag);
  let navigate = useNavigate();
  const [activeState, setActiveState] = useState(window.location.pathname);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [open, setOpen] = useState(false);
  const name = getName();

  const signout = () => {
    dispatch(logOutUser());
    destroySession();
    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
    setActiveState(e);
  };

  const handleSelectNavMenu = (url) => {
    setAnchorElNav(null);
    setActiveState(url);
    navigate(url);
  };

  const openLoginPopup = () => {
    dispatch(showPrompt({ category: promptCategoryType.LOGIN }));
    handleCloseNavMenu();
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getPages = () =>
    loggedIn
      ? isAuthorized("SUPER_ADMIN")
        ? superAdminPages
        : isAuthorized("ADMIN")
        ? adminPages
        : userPages
      : pages;

  useEffect(() => {
    setActiveState(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: themeStyles.primaryColor, margin: 0 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={logo}
            alt="WHS Sports Hub"
            width="80px"
            onClick={() => navigate("/")}
            className="logo-img"
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ marginLeft: "85%" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {getPages().map(({ route, url }) => (
                <MenuItem key={route} onClick={() => handleSelectNavMenu(url)}>
                  <StyledLink
                    to={url}
                    style={{
                      color:
                        activeState === url ? "grey" : themeStyles.primaryColor,
                    }}
                  >
                    {route}
                  </StyledLink>
                </MenuItem>
              ))}
              {!loggedIn && (
                <MenuItem key="login" onClick={openLoginPopup}>
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
              )}
              {loggedIn && [
                <MenuItem key="logout" onClick={signout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>,
              ]}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              maxWidth: "75%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {pages.map(({ route, url }) => (
              <StyledLink key={route} to={url}>
                <Button
                  key={route}
                  onClick={() => handleCloseNavMenu(url)}
                  sx={{
                    textTransform: "unset",
                    minWidth: "5rem",
                    fontSize: "14px",
                    color: activeState === url ? "#fff" : themeStyles.textColor,
                  }}
                >
                  {route}
                </Button>
              </StyledLink>
            ))}
            <div className="loginContainer">
              {!loggedIn && (
                <Button
                  className="login-btn"
                  onClick={() =>
                    dispatch(showPrompt({ category: promptCategoryType.LOGIN }))
                  }
                  key="login-btn"
                  sx={{
                    backgroundColor: themeStyles.secondaryColor,
                    color: themeStyles.secondaryTextColor,
                    maxHeight: "2rem",
                    textTransform: "unset",
                    marginLeft: "4rem",
                  }}
                >
                  Login
                </Button>
              )}
              {loggedIn && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<LoginPanel />}
                      placement="bottom-end"
                    >
                      <Avatar onClick={handleTooltipOpen}>{name[0]}</Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
