export const FETCH_MY_BOOKINGS = "FETCH_MY_BOOKINGS";
export const SET_MY_BOOKINGS = "SET_MY_BOOKINGS";
export const RESET_MY_BOOKINGS = "RESET_MY_BOOKINGS";

export const fetchMyBookings = () => ({
  type: FETCH_MY_BOOKINGS,
});

export const setMyBookings = ({ list }) => ({
  type: SET_MY_BOOKINGS,
  payload: { list },
});

export const resetMyBookings = () => ({
  type: RESET_MY_BOOKINGS,
});

const initialState = {
  list: { past: [], today: [], upcoming: [] },
};

const myBookings = (state = initialState, action) => {
  if (action.type === SET_MY_BOOKINGS) {
    return {
      ...action.payload,
    };
  }

  if (action.type === RESET_MY_BOOKINGS) {
    return initialState;
  }

  return state;
};

export default myBookings;

export const getMyBookings = ({ myBookings: { list } }) => list;
