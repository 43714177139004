export const SET_USER_INVALID = "SET_USER_INVALID";
export const SEND_OTP = "SEND_OTP";
export const REGISTER_USER = "REGISTER_USER";
export const SEND_MOBILE_NUMBER = "SEND_MOBILE_NUMBER";
export const SET_USER_EXIST = "SET_USER_EXIST";
export const SET_REGISTERED_FLAG = "SET_REGISTERED_FLAG";
export const SET_OTP_INCORRECT = "SET_OTP_INCORRECT";
export const SET_OTP_RECIEVED = "SET_OTP_RECIEVED";
export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const RESET = "LOGIN RESET";

// actions

export const setUserInvalid = () => ({
  type: SET_USER_INVALID,
});

export const setUserExist = () => ({
  type: SET_USER_EXIST,
});

export const setRegisteredFlag = () => ({
  type: SET_REGISTERED_FLAG,
});

export const sendOtp = ({ mobileNumber, mobileOtp }) => ({
  type: SEND_OTP,
  payload: { mobileNumber, mobileOtp },
});

export const registerUser = ({
  firstName,
  lastName,
  emailAddress,
  mobileNumber,
}) => ({
  type: REGISTER_USER,
  payload: {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
  },
});

export const sendMobileNumber = ({ mobileNumber }) => ({
  type: SEND_MOBILE_NUMBER,
  payload: { mobileNumber },
});

export const logInUser = () => ({
  type: LOG_IN_USER,
});

export const logOutUser = () => ({
  type: LOG_OUT_USER,
});

export const setOtpIncorrect = () => ({
  type: SET_OTP_INCORRECT,
});

export const setOtpRecieved = () => ({
  type: SET_OTP_RECIEVED,
});

export const reset = () => ({
  type: RESET,
});

// reducer

const initialState = {
  loggedIn: false,
  userExist: false,
  registered: false,
  otpRecieved: false,
  userInvalid: false,
  otpIncorrect: false,
};

const loginReducer = (state = initialState, action) => {
  if (action.type === SET_USER_INVALID) {
    return {
      ...initialState,
      userInvalid: true,
    };
  }

  if (action.type === LOG_IN_USER) {
    return {
      ...state,
      loggedIn: true,
    };
  }

  if (action.type === LOG_OUT_USER) {
    return {
      ...state,
      loggedIn: false,
    };
  }

  //to get otp
  if (action.type === SEND_MOBILE_NUMBER) {
    return {
      ...initialState,
    };
  }

  if (action.type === SET_USER_EXIST) {
    return {
      ...initialState,
      userExist: true,
      userInvalid: true,
    };
  }

  if (action.type === SET_REGISTERED_FLAG) {
    return {
      ...initialState,
      registered: true,
    };
  }

  if (action.type === SET_OTP_INCORRECT) {
    return {
      ...initialState,
      otpIncorrect: true,
      otpRecieved: true,
    };
  }

  if (action.type === SET_OTP_RECIEVED) {
    return {
      ...initialState,
      otpRecieved: true,
    };
  }

  if (action.type === RESET) {
    return initialState;
  }

  return state;
};

export default loginReducer;

// selectors

export const getLoginFlag = ({ login: { loggedIn } }) => loggedIn;
export const userExist = ({ login: { userExist } }) => userExist;
export const registeredFlag = ({ login: { registered } }) => registered;
export const isUserInvalid = ({ login: { userInvalid } }) => userInvalid;
export const isOtpIncorrect = ({ login: { otpIncorrect } }) => otpIncorrect;
export const hasOtpBeenSent = ({ login: { otpRecieved } }) => otpRecieved;
