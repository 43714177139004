export const SAVE_PROFILE = "SAVE_PROFILE";

export const saveProfile = ({
  firstName,
  lastName,
  emailAddress,
  mobileNumber,
}) => ({
  type: SAVE_PROFILE,
  payload: { firstName, lastName, emailAddress, mobileNumber },
});
