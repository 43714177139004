import { apiAction, API_SUCCESS } from "../dux/api";
import {
  FETCH_BOOKING_RECORD,
  INVOICE_DOWNLOAD,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_STATUS_SUPER,
  DOWNLOAD_BOOKING_RECORD_CSV,
  EDIT_BOOKING_RECORD,
  setBookingRecord,
  setBookingSetting,
} from "../dux/bookingRecords";
import { parseBookingRecords } from "../helpers/bookingRecordsHelper";
import { getBaseApiUrl } from "../helpers/generic";
import { apiCallComplete } from "../dux/apiStatus";
import { openSnackbar } from "../dux/snackbar";
import { API_ERROR } from "../dux/api";

const bookingRecords = () => (next) => (action) => {
  next(action);

  if (action.type === FETCH_BOOKING_RECORD) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/filter`,
        feature: FETCH_BOOKING_RECORD,
        data: {
          startDate: action.payload.startDate ?? null,
          endDate: action.payload.endDate ?? null,
          mobileNumber: action.payload.mobileNumber ?? null,
          sortField: action.payload.sortField ?? "playingTimestamp",
        },
      })
    );
  }

  if (action.type === EDIT_BOOKING_RECORD) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/edit`,
        method: "PUT",
        feature: EDIT_BOOKING_RECORD,
        data: {
          bookingId: action.payload.bookingId ?? null,
          totalAmount: action.payload.totalAmount ?? null,
        },
      })
    );
  }

  if (action.type === `${EDIT_BOOKING_RECORD} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "editBooking" }));
    next(
      openSnackbar({
        message: "Booking has been updated successfully.",
      })
    );
  }

  if (action.type === `${EDIT_BOOKING_RECORD} ${API_ERROR}`) {
    next(apiCallComplete({ api: "editBooking" }));
  }

  if (action.type === INVOICE_DOWNLOAD) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/invoice/${action.payload.id}`,
        feature: INVOICE_DOWNLOAD,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
  }

  if (action.type === DOWNLOAD_BOOKING_RECORD_CSV) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/csv`,
        feature: DOWNLOAD_BOOKING_RECORD_CSV,
        responseType: "blob",
        data: {
          startDate: action.payload.startDate ?? null,
          endDate: action.payload.endDate ?? null,
          mobileNumber: action.payload.mobileNumber ?? null,
          sortField: action.payload.sortField ?? "playingTimestamp",
        },
      })
    );
  }

  if (action.type === `${DOWNLOAD_BOOKING_RECORD_CSV} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "csvDownloaded" }));
    next(
      openSnackbar({
        message: "CSV downloaded successfully.",
      })
    );
  }

  if (action.type === `${DOWNLOAD_BOOKING_RECORD_CSV} ${API_ERROR}`) {
    next(apiCallComplete({ api: "csvDownloaded" }));
  }

  if (action.type === INVOICE_DOWNLOAD) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/invoice/${action.payload.id}`,
        feature: INVOICE_DOWNLOAD,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
  }

  if (action.type === `${INVOICE_DOWNLOAD} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "invoiceDownload" }));
    next(
      openSnackbar({
        message: "Invoice has been download successfully.",
      })
    );
  }

  if (action.type === `${INVOICE_DOWNLOAD} ${API_ERROR}`) {
    next(apiCallComplete({ api: "invoiceDownload" }));
  }

  if (action.type === UPDATE_PAYMENT_STATUS) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/change-payment-status/admin`,
        method: "POST",
        feature: UPDATE_PAYMENT_STATUS,
        data: {
          bookingId: action.payload.bookingId ?? null,
          status: action.payload.status ?? null,
        },
      })
    );
  }

  if (action.type === `${UPDATE_PAYMENT_STATUS} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "bookingStatusChange" }));
    next(
      openSnackbar({
        message: "Booking status had been changed successfully.",
      })
    );
  }

  if (action.type === `${UPDATE_PAYMENT_STATUS} ${API_ERROR}`) {
    next(apiCallComplete({ api: "bookingStatusChange" }));
  }

  if (action.type === UPDATE_PAYMENT_STATUS_SUPER) {
    next(
      apiAction({
        url: `${getBaseApiUrl()}api/v1/booking/change-payment-status/super/admin`,
        method: "POST",
        feature: UPDATE_PAYMENT_STATUS_SUPER,
        data: {
          bookingId: action.payload.bookingId ?? null,
          status: action.payload.status ?? null,
        },
      })
    );
  }

  if (action.type === `${UPDATE_PAYMENT_STATUS_SUPER} ${API_SUCCESS}`) {
    next(apiCallComplete({ api: "bookingStatusChange" }));
    next(
      openSnackbar({
        message: "Booking status had been changed successfully.",
      })
    );
  }

  if (action.type === `${UPDATE_PAYMENT_STATUS_SUPER} ${API_ERROR}`) {
    next(apiCallComplete({ api: "bookingStatusChange" }));
    next(
      openSnackbar({
        message: "Failed to update payment status.",
      })
    );
  }

  if (action.type === `${FETCH_BOOKING_RECORD} ${API_SUCCESS}`) {
    const parsedData = parseBookingRecords(action.payload);

    next(setBookingRecord({ list: parsedData }));
  }
};

export default bookingRecords;
