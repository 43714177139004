import moment from "moment";

export const mapStartTimeToIndex = {
  "06:00": 12,
  "07:00": 14,
  "08:00": 16,
  "09:00": 18,
  "10:00": 20,
  "11:00": 22,
  "12:00": 24,
  "13:00": 26,
  "14:00": 28,
  "15:00": 30,
  "16:00": 32,
  "17:00": 34,
  "18:00": 36,
  "19:00": 38,
  "20:00": 40,
  "21:00": 42,
  "22:00": 44,
  "23:00": 46,
};

export const mapTurf = {
  turfA: 1,
  turfB: 2,
  turfD: 1,
  turfE: 2,
  turfC: 3,
};

export const isSlotAvailable = (list, startTime, duration) => {
  if (startTime === "" || list.length === 0) {
    return false;
  }

  const startIndex = mapStartTimeToIndex[startTime];
  const lastIndex = startIndex + duration * 2 - 1;
  for (let i = startIndex; i <= lastIndex; i++) {
    if (list[i] === false) {
      return false;
    }
  }
  return true;
};

export const addTimeHours = (time, hours) =>
  `${moment(time, ["h:m a", "H:m"]).add(hours, "hour").format("HH:mm")}`;

export const parseSlotInterval = ({ startTime, endTime, cost }) => {
  let res = [];
  const intervals =
    (mapStartTimeToIndex[endTime] - mapStartTimeToIndex[startTime]) / 2;

  for (let i = 0; i < intervals; i++) {
    res = [...res, { time: addTimeHours(startTime, i), cost }];
  }

  return res;
};

export const parseSlots = (list) => {
  const len = list.length;
  let res = [];

  for (let i = 0; i < len; i++) {
    res = [...res, ...parseSlotInterval(list.at(i))];
  }

  return res;
};

const parseAvailibityArray = (list, slots) => {
  const len = slots.length;
  let res = [];

  for (let i = 0; i < len; i++) {
    const start = mapStartTimeToIndex[slots.at(i).startTime];
    const end = mapStartTimeToIndex[slots.at(i).endTime];
    for (let j = start; j < end; j++) {
      res = [...res, j];
    }
  }

  return list.map((flag, index) => (res.includes(index) ? flag : false));
};

export const parseBookNowApiData = ({
  date,
  bookingAllowed,
  slotsAvailability: {
    turfA: availibilityTurfA,
    turfB: availibilityTurfB,
    turfC: availibilityTurfC,
  },
  routine: { turfA, turfB, turfC, turfD, turfE },
}) => ({
  date,
  bookingAllowed,
  slotsAvailability: {
    turfA: parseAvailibityArray(availibilityTurfA, turfA),
    turfB: parseAvailibityArray(availibilityTurfB, turfB),
    turfC: turfC ? parseAvailibityArray(availibilityTurfC, turfC) : [],
  },
  slots: {
    turfA: parseSlots(turfA),
    turfB: parseSlots(turfB),
    turfC: turfC ? parseSlots(turfC) : [],
    turfD: turfD ? parseSlots(turfD) : [],
    turfE: turfE ? parseSlots(turfE) : [],
  },
});

export const getTotalCost = (list, startTime, duration) => {
  const index = list.findIndex(({ time }) => time === startTime);
  let sum = 0;
  for (let i = index; i < index + duration; i++) {
    sum = sum + list.at(i).cost;
  }
  return sum;
};

export const parseNewBookingApiData = ({
  order_id,
  transactionId,
  currency,
  amount,
}) => ({ order_id, transactionId, currency, amount });
