import React from "react";
import { Spin } from "antd";

const LoaderForButton = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin className={"white-loader"} size={"small"} />
    </div>
  );
};

export default LoaderForButton;
