import { Paper, Typography } from "@mui/material";
import aboutImage from "../../assets/facilities-1.jpg";

const AboutUs = () => {
  return (
    <div className="main-wrapper">
      <div className="background">
        <img
          src={aboutImage}
          alt="Footbal"
          loading="lazy"
          style={{ width: "100%" }}
        />
      </div>
      <div className="white-paper">
        <Paper
          style={{
            width: "88%",
            position: "relative",
            top: "-15px",
            height: "calc(100% + 15px)",
            padding: "20px 32px 20px 32px",
            boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.09)",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            style={{ color: "#01245a", marginBottom: "20px" }}
          >
            <strong>About Us</strong>
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#797979", fontSize: "14px" }}
          >
            WHS Sports Hub is a state of the art multi sports facility in
            Srinagar (J&K). We provide an optimal playing experience to all our
            customers with a well balanced sports curriculum.There’s no better
            way to recharge than let go of your worries at our state of the art
            facility.We are proud to say that we have everything it takes for an
            ideal Pay n Play venue in the valley. Events, Tournaments can be
            conducted with ease at our venue. This is calling out to those who
            work hard and play harder. Come play with us during the day or
            night. Come be a part of our facility and create memories you will
            always cherish.
            <br />
            <br />
            Get your game on!
          </Typography>
        </Paper>
      </div>
      <div className="footer"></div>
    </div>
  );
};

export default AboutUs;
