import { getBaseApiUrl } from "./generic";

export const getNewBookingUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/new/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/new/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/new`;
};

export const getMakeSlotAvailableUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/make-slots-available/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/make-slots-available/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/make-slots-available`;
};

export const getMarkDaysOffHolidayUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/mark-date/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/mark-date/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/mark-date/football`;
};

export const getFetchMarkedDaysOffHolidayUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/marked-date/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/marked-date/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/marked-date/football`;
};

export const getFetchAvailableSlotsUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/available-slots/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/available-slots/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/available-slots/football`;
};

export const getBlockUnblockSlotUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/block-slots/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/block-slots/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/block-slots`;
};

export const getFetchBookingSettingUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/setting/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/setting/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/setting/football`;
};

export const getUpdateBookingSettingUrl = (sport) => {
  if (sport === "Cricket Net") {
    return `${getBaseApiUrl()}api/v1/booking/setting/cricket-net`;
  }
  if (sport === "Pickle Ball") {
    return `${getBaseApiUrl()}api/v1/booking/setting/pickle-ball`;
  }

  return `${getBaseApiUrl()}api/v1/booking/setting/football`;
};
