import { useState } from "react";
import cx from "classnames";
import Calendar from "./Calendar/Calendar";
import styles from "./MarkDateOffHoliday.module.css";

const Tabs = [
  { title: "MARK HOLIDAYS", key: 1 },
  { title: "MARK OFF DAYS", key: 2 },
];

const MarkDateOffHoliday = () => {
  const [type, setType] = useState(1);
  const [selectedSport, setSelectedSport] = useState("Football");

  const clickHandler = (key) => {
    setType(key);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <div className={styles.sportContainer}>
            <h3 className={styles.heading3}>Select Sports:</h3>
            <div className={styles.sportsCards}>
              <button
                onClick={() => setSelectedSport("Football")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Football",
                })}
              >
                <div className={styles.footballSportCard} />
                <span>Football</span>
              </button>
              <button
                onClick={() => setSelectedSport("Box Cricket")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Box Cricket",
                })}
              >
                <div className={styles.cricketSportCard} />
                <span>Box Cricket</span>
              </button>

              <button
                onClick={() => setSelectedSport("Cricket Net")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Cricket Net",
                })}
              >
                <div className={styles.cricketSportCard} />
                <span>Cricket Nets</span>
              </button>

              <button
                onClick={() => setSelectedSport("Pickle Ball")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Pickle Ball",
                })}
              >
                <div className={styles.pickeBallSportCard} />
                <span>Pickleball</span>
              </button>
            </div>
          </div>
          <h3 className={styles.heading3}>Select Type:</h3>
          <div className={styles.typeContainer}>
            {Tabs.map(({ title, key }) => (
              <div
                key={key}
                className={cx(styles.typeCard, {
                  [styles.selectedType]: type === key,
                })}
                onClick={() => clickHandler(key)}
              >
                {title}
              </div>
            ))}
          </div>
          <h3 className={styles.heading3}>Select Date:</h3>
          <Calendar type={type} sport={selectedSport} />
        </div>
      </div>
    </>
  );
};

export default MarkDateOffHoliday;
