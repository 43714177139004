import { configureStore } from "@reduxjs/toolkit";
import bookingRecordsReducer from "../dux/bookingRecords";
import loginReducer from "../dux/login";
import myBookingsReducer from "../dux/myBookings";
import promptReducer from "../dux/prompt";
import snackbarReducer from "../dux/snackbar";
import apiStatus from "../dux/apiStatus";
import bookNowReducer from "../dux/bookNow";
import myBookingTabReducer from "../dux/myBookingTab";
import uiBookNow from "../dux/ui/uiBookNow";
import markDaysOffHolidayReducer from "../dux/markDaysOffHoliday";

import bookingRecords from "../middleware/bookingRecords";
import apiMiddleware from "../middleware/core/api";
import feedback from "../middleware/feedback";
import login from "../middleware/login";
import myBookings from "../middleware/myBookings";
import profile from "../middleware/profile";
import snackbar from "../middleware/snackbar";
import bookNow from "../middleware/bookNow";
import store from "../middleware/store";
import apiError from "../middleware/core/apiError";
import blockSlot from "../middleware/blockSlot";
import markDaysOffHoliday from "../middleware/markDaysOffHoliday";
import bookingSetting from "../middleware/bookingSetting";
import bookingSettingReducer from "../dux/bookingSetting";

export default configureStore({
  reducer: {
    myBookings: myBookingsReducer,
    markDays: markDaysOffHolidayReducer,
    login: loginReducer,
    prompt: promptReducer,
    snackbar: snackbarReducer,
    bookNow: bookNowReducer,
    bookingRecords: bookingRecordsReducer,
    myBookingTab: myBookingTabReducer,
    bookingSetting: bookingSettingReducer,
    apiStatus,
    uiBookNow,
  },
  middleware: [
    markDaysOffHoliday,
    blockSlot,
    store,
    bookNow,
    myBookings,
    profile,
    feedback,
    login,
    snackbar,
    bookingRecords,
    bookingSetting,
    apiMiddleware,
    apiError,
  ],
});
