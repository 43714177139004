import cx from "classnames";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenralRates from "./GeneralRates/GeneralRates";
import SpecialRates from "./SpecialRates/SpecialRates";
import styles from "./AdminPortal.module.css";

const Tabs = [
  { title: "General Rates", key: "general-rates" },
  { title: "Special Rates", key: "special-rates" },
];

const AdminPortal = () => {
  const [selectedSport, setSelectedSport] = useState("Football");
  const navigate = useNavigate();
  const { tab } = useParams();

  const changeHandler = (key) => {
    navigate(`/admin-portal/${key}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainWrapper}>
        <div className={styles.sidebar}>
          <div className={styles.tabContainer}>
            <p className={styles.heading3}>Select Sports:</p>
            <div className={styles.availableSport}>
              <button
                onClick={() => setSelectedSport("Football")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Football",
                })}
              >
                Football
              </button>
              <button
                onClick={() => setSelectedSport("Box Cricket")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Box Cricket",
                })}
              >
                Box Cricket
              </button>
              <button
                onClick={() => setSelectedSport("Cricket Net")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Cricket Net",
                })}
              >
                Cricket Nets
              </button>
              <button
                onClick={() => setSelectedSport("Pickle Ball")}
                className={cx(styles.sportCard, {
                  [styles.selectedSport]: selectedSport === "Pickle Ball",
                })}
              >
                Pickle Ball
              </button>
            </div>
          </div>
          <div className={styles.tabContainer}>
            <p className={styles.heading3}>Select Rates Type:</p>
            {/* 
            {Tabs.map(({ title, key }) => (
              <div
                key={key}
                onClick={() => changeHandler(key)}
                className={cx(styles.tab, { [styles.activeTab]: key === tab })}
              >
                {title}
              </div>
            ))} */}
            <div className={styles.rateTabContainer}>
              {Tabs.map(({ title, key }) => (
                <div
                  key={key}
                  onClick={() => changeHandler(key)}
                  className={cx(styles.tab, {
                    [styles.activeTab]: key === tab,
                  })}
                >
                  {title}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.main}>
          {tab === "general-rates" && <GenralRates sport={selectedSport} />}
          {tab === "special-rates" && <SpecialRates sport={selectedSport} />}
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
};

export default AdminPortal;
