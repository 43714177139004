import { apiAction, API_ERROR, API_SUCCESS } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import { BLOCK_UNBLOCK_SLOT } from "../dux/blockSlot";
import { fetchBookNowDetail } from "../dux/bookNow";
import { openSnackbar } from "../dux/snackbar";
import { getBlockUnblockSlotUrl } from "../helpers/apiEndPoints";

const blockSlot =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === BLOCK_UNBLOCK_SLOT) {
      const { turfNumber, slotDate, slotArr, sport } = action.payload;
      next(apiCallStart({ api: "blockUnblock" }));
      next(
        apiAction({
          url: getBlockUnblockSlotUrl(sport),
          feature: BLOCK_UNBLOCK_SLOT,
          method: "POST",
          data: { turfNumber, slotDate, slotArr },
          meta: { slotDate, sport },
        })
      );
    }

    if (action.type === `${BLOCK_UNBLOCK_SLOT} ${API_SUCCESS}`) {
      next(apiCallComplete({ api: "blockUnblock" }));
      next(
        openSnackbar({
          message: "Slots have been blocked/unblocked successfully.",
        })
      );
      dispatch(
        fetchBookNowDetail({
          date: action.meta.slotDate,
          sport: action.meta.sport,
        })
      );
    }

    if (action.type === `${BLOCK_UNBLOCK_SLOT} ${API_ERROR}`) {
      next(apiCallComplete({ api: "blockUnblock" }));
    }
  };

export default blockSlot;
