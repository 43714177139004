import { SESSION_EXPIRING_TIME } from "./variables";

const SESSION_ID = "LOGGED_IN";
const SESSION_EXPIRE_TIME = "SESSION_EXPIRE_TIME";

const saveSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

const getSessionItem = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

const deleteSession = (key) => {
  sessionStorage.removeItem(key);
};

export const updateSessionExpireTime = (value) =>
  saveSession(SESSION_EXPIRE_TIME, value);

export const startSession = (value) => {
  saveSession(SESSION_ID, value);
  updateSessionExpireTime(SESSION_EXPIRING_TIME);
};

export const getSession = () => getSessionItem(SESSION_ID);
export const destroySession = () => deleteSession(SESSION_ID);

export const loggedIn = () => (getSession() ? true : false);

export const getName = () =>
  getSession() ? getSession().userData.user.firstName : "";

export const getRole = () =>
  getSession() ? getSession().userData.user.roles : "";

export const getUser = () => (getSession() ? getSession().userData.user : {});

export const getAccessToken = () =>
  getSession() ? getSession().access_token : "";

export const updateUser = (value) => {
  const updatedSession = {
    ...getSession(),
    userData: { user: { ...value, roles: getRole() } },
  };
  saveSession(SESSION_ID, JSON.stringify(updatedSession));
};

export const isAuthorized = (role) => {
  const roles = getRole();
  const is_allowed = roles.filter((r) => {
    return r == role;
  });
  return is_allowed.length > 0;
};
