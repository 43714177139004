import { apiAction, API_ERROR, API_SUCCESS } from "../dux/api";
import { apiCallComplete, apiCallStart } from "../dux/apiStatus";
import {
  fetchBookNowDetail,
  FETCH_BOOK_NOW_DETAILS,
  MAKE_SLOT_AVAILABLE,
  NEW_BOOKING,
  setBookNowDetail,
  setNewBookingData,
  VERIFY_PAYMENT,
} from "../dux/bookNow";
import { openSnackbar } from "../dux/snackbar";
import { completeBooking } from "../dux/ui/uiBookNow";
import {
  getFetchAvailableSlotsUrl,
  getMakeSlotAvailableUrl,
  getNewBookingUrl,
} from "../helpers/apiEndPoints";
import {
  parseBookNowApiData,
  parseNewBookingApiData,
} from "../helpers/bookNowHelper";
import { getBaseApiUrl } from "../helpers/generic";

const bookNow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === FETCH_BOOK_NOW_DETAILS) {
      next(apiCallStart({ api: "fetchBookNowDetail" }));
      next(
        apiAction({
          url: getFetchAvailableSlotsUrl(action.payload.sport),
          feature: FETCH_BOOK_NOW_DETAILS,
          data: {
            date: action.payload.date.toString(),
          },
        })
      );
    }

    if (action.type === `${FETCH_BOOK_NOW_DETAILS} ${API_SUCCESS}`) {
      next(apiCallComplete({ api: "fetchBookNowDetail" }));
      const parsedData = parseBookNowApiData(action.payload.response);
      next(setBookNowDetail(parsedData));
    }

    if (action.type === `${FETCH_BOOK_NOW_DETAILS} ${API_ERROR}`) {
      next(apiCallComplete({ api: "fetchBookNowDetail" }));
    }

    if (action.type === VERIFY_PAYMENT) {
      const {
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
        transactionId,
        sport,
        turfNumber,
        slotDate,
        slotTime,
        duration,
        paidAmount,
        totalAmount,
      } = action.payload;
      next(
        apiAction({
          url: `${getBaseApiUrl()}api/v1/booking/verify`,
          method: "POST",
          feature: VERIFY_PAYMENT,
          data: {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
            transactionId,
            sport,
            turfNumber,
            slotDate,
            slotTime,
            duration,
            paidAmount,
            totalAmount,
          },
          meta: { slotDate, sport },
        })
      );
    }

    if (action.type === `${VERIFY_PAYMENT} ${API_SUCCESS}`) {
      next(apiCallComplete({ api: "newBooking" }));
      dispatch(
        fetchBookNowDetail({
          date: action.meta.slotDate,
          sport: action.meta.sport,
        })
      );
      next(completeBooking());
      next(
        openSnackbar({
          message: "Your payment has been successfully recieved.",
        })
      );
    }

    if (action.type === `${VERIFY_PAYMENT} ${API_ERROR}`) {
      next(apiCallComplete({ api: "newBooking" }));
    }

    if (action.type === NEW_BOOKING) {
      const {
        sport,
        emailAddress,
        slotDate,
        slotTime,
        duration,
        turfNumber,
        paidAmount,
        totalAmount,
      } = action.payload;
      next(apiCallStart({ api: "newBooking" }));

      next(
        apiAction({
          url: getNewBookingUrl(sport),
          feature: NEW_BOOKING,
          method: "POST",
          data: {
            sport,
            emailAddress,
            slotDate,
            slotTime,
            duration,
            turfNumber,
            paidAmount,
            totalAmount,
          },
        })
      );
    }

    if (action.type === `${NEW_BOOKING} ${API_SUCCESS}`) {
      const parsedData = parseNewBookingApiData(action.payload);
      next(setNewBookingData(parsedData));
    }

    if (action.type === `${NEW_BOOKING} ${API_ERROR}`) {
      next(apiCallComplete({ api: "newBooking" }));
    }

    if (action.type === MAKE_SLOT_AVAILABLE) {
      const { slotDate, slotTime, turfNumber, sport } = action.payload;

      next(
        apiAction({
          url: getMakeSlotAvailableUrl(sport),
          feature: MAKE_SLOT_AVAILABLE,
          method: "POST",
          data: {
            slotDate,
            slotTime,
            turfNumber,
          },
        })
      );
    }
  };

export default bookNow;
