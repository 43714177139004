import spacetime from "spacetime";

// @dateString <string> "2019-05-29T15:00:00+05:30"
// type <number>
// timezone <string> 'Europe/Berlin'

export const formatDate = (dateString, type, timezone) => {
  const localTime = spacetime(dateString);
  const date = timezone ? localTime.goto(timezone) : localTime;

  switch (type) {
    case 1:
      return date.format("{month-short} {date-pad}, {year}"); // May 29, 2019

    case 2:
      return date.unixFmt("MMM dd, yyyy HH:mm"); // May 29, 2019 15:00

    case 3:
      return date.unixFmt("dd/MM/yyyy"); // 2019-05-29

    case 4:
      return date.format(
        "{month-short} {date-pad}, {hour-pad}:{minute-pad} {ampm}"
      ); // May 29, 3:00 pm

    case 5:
      return date.format("{hour-pad}:{minute-pad} {ampm}"); // 03:00 pm

    case 6:
      return date.format(
        "{month-short} {date-pad}, {year} {hour-pad}:{minute-pad} {ampm}"
      ); // May 29, 2019 03:00 pm

    default:
      return dateString;
  }
};

export const getCurrentTimeString = () => {
  const today = new Date();
  const hour = today.getHours();
  const minute = today.getMinutes();

  return ("0" + hour).slice(-2) + ":" + minute;
};

export const convert24hourTo12hourTime = (time) =>
  new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
