import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallComplete,
  isFetchMarkOffHolidayPending,
  isMarkOffHolidayPending,
} from "../../../dux/apiStatus";
import {
  fetchMarkDaysOffHoliday,
  getMarkDays,
  markDaysOffHoliday,
} from "../../../dux/markDaysOffHoliday";
import Loader from "../../shared/UI/Loader/Loader";
import LoaderForButton from "../../shared/UI/LoaderForButton";
import styles from "./Calendar.module.css";

const format = "YYYY/MM/DD";

const typeToText = { 1: "holiday", 2: "off" };

export default function App({ type, sport }) {
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  const markDaysFetching = useSelector(isFetchMarkOffHolidayPending);
  const markOffHolidayPending = useSelector(isMarkOffHolidayPending);
  const alreadySelectedDates = useSelector(getMarkDays)[type].map(
    ({ date }) => date
  );

  useEffect(() => {
    dispatch(fetchMarkDaysOffHoliday({ sport }));

    return () => {
      dispatch(apiCallComplete({ api: "markOffHoliday" }));
    };
  }, [sport, dispatch]);

  useEffect(() => {
    setDates([]);
  }, [type, markDaysFetching]);

  const submitHandler = () => {
    dispatch(
      markDaysOffHoliday({
        dateArr: dates.map((date) => date.format("DD/MM/YYYY")),
        type,
        sport,
      })
    );
  };

  return (
    <div className={styles.container}>
      {markDaysFetching ? (
        <Loader />
      ) : (
        <>
          <span className={styles.dateTitle}>Already {typeToText[type]}</span>
          <div className={styles.datesContainer}>
            {alreadySelectedDates.length === 0 &&
              `No dates are ${typeToText[type]}.`}

            {alreadySelectedDates.map((date) => (
              <span
                key={date}
                className={cx(styles.unavailable, styles.dateCard)}
              >
                {date}
              </span>
            ))}
          </div>
          <Calendar
            minDate={new Date()}
            maxDate={new DateObject().add(60, "day")}
            format={format}
            value={dates}
            onChange={setDates}
          ></Calendar>

          <span className={styles.dateTitle}>Selected By You</span>
          <div className={styles.datesContainer}>
            {dates.length === 0 && "No dates are selected yet."}
            {dates.sort().map((date) => (
              <span
                key={date}
                className={cx(styles.dateCard, {
                  [styles.madeAvailable]: alreadySelectedDates.includes(
                    date.format("DD/MM/YYYY")
                  ),
                })}
              >
                {date.format("DD/MM/YYYY")}
              </span>
            ))}
          </div>
          <button
            className={styles.btn}
            onClick={submitHandler}
            disabled={isEmpty(dates) || markOffHolidayPending}
          >
            {markOffHolidayPending ? <LoaderForButton /> : "PROCEED"}
          </button>
        </>
      )}
    </div>
  );
}
