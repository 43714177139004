export const UPDATE_BOOKING_SETTING = "UPDATE_BOOKING_SETTING";
export const FETCH_BOOKING_SETTING = "FETCH_BOOKING_SETTING";
export const SET_BOOKING_SETTING = "SET_BOOKING_SETTING";
export const RESET_BOOKING_SETTING = "SET_BOOKING_SETTING";

export const fetchBookingSetting = ({ sport }) => ({
  type: FETCH_BOOKING_SETTING,
  payload: { sport },
});

export const setBookingSetting = ({ bookingSetting }) => ({
  type: SET_BOOKING_SETTING,
  payload: {
    bookingSetting: bookingSetting?.response?.json,
  },
});

export const updateBookingSetting = ({ json, sport }) => ({
  type: UPDATE_BOOKING_SETTING,
  payload: { json, sport },
});

export const resetBookingSetting = () => ({
  type: RESET_BOOKING_SETTING,
});

const initialState = {
  bookingSetting: {},
};

const bookingSettingReducer = (state = initialState, action) => {
  if (action.type === SET_BOOKING_SETTING) {
    return {
      ...state,
      bookingSetting: action.payload,
    };
  }

  if (action.type === RESET_BOOKING_SETTING) {
    return initialState;
  }

  return state;
};

export default bookingSettingReducer;

export const getBookingSetting = ({ bookingSetting: { bookingSetting } }) =>
  bookingSetting;
