import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import "dayjs/locale/en-in";

const Calendar = ({ date, setDate }) => {
  const handleChange = (value) => {
    setDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-in"}>
      <DesktopDatePicker
        label=""
        // inputFormat="DD/MM/YYYY"
        value={date}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
